import {createContext, useEffect, useState} from "react";
import {getUserRequest} from "../api/getUserRequest";
import {redirect} from "react-router-dom";

export const UserContext = createContext();

export const UserContextProvider = ({children}) => {
    const [user, setUser] = useState(null);

    const isLoggedIn = async () => {
        let data = await getUserRequest();

        if (data !== '') {
            setUser(data);
        }
    }

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);

        redirect('/login');
    }

    useEffect(() => {
        const getUser = async () => {
            await isLoggedIn();
        }

        getUser();
    }, []);

    return (
        <UserContext.Provider value={{user, isLoggedIn, logout}}>
            {children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;