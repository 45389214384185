import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserContextProvider from "./context/UserContext";
// import {GoogleOAuthProvider} from "@react-oauth/google";

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <UserContextProvider>
        {/*<GoogleOAuthProvider clientId="873591830812-52brv89imui4ae1kgiq00895ptfdmp3k.apps.googleusercontent.com">*/}
        <Suspense>
            <App/>
        </Suspense>
        {/*</GoogleOAuthProvider>*/}
    </UserContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
