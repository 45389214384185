import './i18n/config';
import React, {useContext} from 'react';
import axios from "axios";
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./theme";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {UserContext} from "./context/UserContext";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import ScrollToTop from './pages/static/ScrollToTop';
import Activities from './pages/Activities';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {frFR, LocalizationProvider, nlNL} from '@mui/x-date-pickers';
import i18n from "i18next";
import Appointment from "./pages/Appointment";
import Prices from "./pages/Prices";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/private/api`;

const Appointments = React.lazy(() => import('./pages/Appointments'));
const Groups = React.lazy(() => import('./pages/Groups'));
const Contact = React.lazy(() => import('./pages/Contact'));
const FrequentlyAskedQuestions = React.lazy(() => import('./pages/FrequentlyAskedQuestions'));
const Activity = React.lazy(() => import('./pages/Activity'));
const Flyboard = React.lazy(() => import('./pages/static/Flyboard'));
const Aquapark = React.lazy(() => import('./pages/static/Aquapark'));
const StandupPaddle = React.lazy(() => import('./pages/static/StandupPaddle'));

export default function App() {
    const {user} = useContext(UserContext);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        // createTheme(theme, locales[lng === 'fr' ? 'frFR' : 'nlNL']);
    }

    return <ThemeProvider theme={theme}>
        <CssBaseline/>
        <LocalizationProvider dateAdapter={AdapterMoment} localeText={i18n.language === 'fr' ? frFR.components.MuiLocalizationProvider.defaultProps.localeText : nlNL.components.MuiLocalizationProvider.defaultProps.localeText}>
            <BrowserRouter>
                <ScrollToTop>
                    <Routes>
                        <Route path="/" element={<Home changeLanguage={changeLanguage}/>}/>
                        <Route path="/appointment" element={<Appointments changeLanguage={changeLanguage}/>}/>
                        <Route path="/appointment/:id" element={<Appointment changeLanguage={changeLanguage}/>}/>
                        <Route path="/groups" element={<Groups changeLanguage={changeLanguage}/>}/>
                        <Route path="/contact" element={<Contact changeLanguage={changeLanguage}/>}/>
                        <Route path="/faq" element={<FrequentlyAskedQuestions changeLanguage={changeLanguage}/>}/>
                        <Route path="/activities" element={<Activities changeLanguage={changeLanguage}/>}/>
                        <Route path="/prices" element={<Prices changeLanguage={changeLanguage}/>}/>

                        <Route path="/activities/flyboard" element={<Activity
                            changeLanguage={changeLanguage}
                            background='/static/images/page_flyboard.webp'
                            component={<Flyboard/>}/>}/>
                        <Route path="/activities/sup" element={<Activity
                            changeLanguage={changeLanguage}
                            background='/static/images/page_sup.gif'
                            component={<StandupPaddle/>}/>}/>
                        <Route path="/activities/aquapark" element={<Activity
                            changeLanguage={changeLanguage}
                            background='/static/images/aquapark24.webp'
                            component={<Aquapark/>}/>}/>

                        <Route path="/login" element={<Login/>}/>

                        {!user && (
                            <Route path="/dashboard/*" element={<Login/>}/>
                        )}

                        {user && (
                            <Route path="/dashboard/*" element={<Dashboard/>}/>
                        )}
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
        </LocalizationProvider>
    </ThemeProvider>;
}
