import {Button, Container, Stack, TextField} from "@mui/material";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {UserContext} from "../context/UserContext";
import axios from "axios";
import {useNavigate} from "react-router-dom";

export default function Login() {
    const {t} = useTranslation();
    const {user} = useContext(UserContext);
    const [txtLogin, setTxtLogin] = useState<string>();
    const [password, setPassword] = useState<string>();
    const navigate = useNavigate();

    const login = () => {
        axios.post('users.php', {
            login: txtLogin,
            password: password,
        }).then(response => {
            localStorage.setItem('token', response.data.token);
            navigate(0);
        });
    }

    return (<Container>
        {user ? <></> : <Stack justifyContent="center"
                               spacing={4}
                               pt={30}>
            <TextField id="login"
                       fullWidth
                       onChange={e => setTxtLogin(e.target.value)}
                       label={t('LOGIN')}
            />
            <TextField id="password"
                       type="password"
                       fullWidth
                       onChange={e => setPassword(e.target.value)}
                       label={t('PASSWORD')}
            />
            <Button variant="contained"
                    onClick={login}>{t('LOGIN_BUTTON')}</Button>
        </Stack>}
    </Container>);
}