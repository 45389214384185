import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Button,
    Container,
    IconButton,
    Paper,
    Stack
} from "@mui/material";
import moment, {Moment} from "moment";
import {ArrowLeftIcon, ArrowRightIcon} from "@mui/x-date-pickers";
import {useGetAppointments} from "../../hooks/useGetAppointments";
import MonthView from "../../components/dashboard/calendar/Monthview";

type Props = {
    setTitle: (title: string) => void
}

function format(type: string) {
    switch (type) {
        case "MONTH_VIEW":
            return "MMMM, YYYY";
        case "DAY_VIEW":
            return "MMMM D, YYYY"
    }
}

export default function Calendar(props: Props) {
    const {setTitle} = props;
    const {t} = useTranslation();
    const {appointments} = useGetAppointments();

    const [currentDate, setCurrentDate] = useState<Moment>(moment());
    const [currentType, setCurrentType] = useState<string>("MONTH_VIEW");

    useEffect(() => {
        setTitle(t('DASHBOARD_CALENDAR_TITLE'))
    }, [t, setTitle]);

    return <Container sx={{
        pb: 10
    }}>
        <Stack component={Paper}
               sx={{
                   mt: 4
               }}>
            <Stack flexDirection="row"
                   alignItems="center"
                   justifyContent="space-between"
                   sx={{
                       m: 2,
                       '& > *': {
                           ml: 2,
                           mr: 2
                       }
                   }}>
                <div>
                    <Button variant="contained" onClick={() => setCurrentDate(moment())}>
                        {t('TODAY')}
                    </Button>
                    <IconButton aria-label="expand row"
                                size="small"
                                onClick={() => setCurrentDate(currentDate.clone().subtract(1, currentType === 'MONTH_VIEW' ? 'month' : 'day'))}
                                sx={{
                                    ml: 2,
                                    mr: 2
                                }}>
                        <ArrowLeftIcon/>
                    </IconButton>
                    <IconButton aria-label="expand row"
                                size="small"
                                onClick={() => setCurrentDate(currentDate.clone().add(1, currentType === 'MONTH_VIEW' ? 'month' : 'day'))}
                                sx={{
                                    ml: 2,
                                    mr: 2
                                }}>
                        <ArrowRightIcon/>
                    </IconButton>
                    <span>{currentDate.format(format(currentType))}</span>
                </div>
                {/*<div>*/}
                {/*    <ToggleButtonGroup size="small" exclusive onChange={(event, value) => setCurrentType(value)}*/}
                {/*                       value={currentType}>*/}
                {/*        <ToggleButton value='DAY_VIEW' key="DAY_VIEW">{t('DAY_VIEW')}</ToggleButton>*/}
                {/*        <ToggleButton value='MONTH_VIEW' key="MONTH_VIEW">{t('MONTH_VIEW')}</ToggleButton>*/}
                {/*    </ToggleButtonGroup>*/}
                {/*</div>*/}
            </Stack>
            {currentType === 'MONTH_VIEW' ?
                <MonthView currentDate={currentDate}
                           appointments={appointments}
                           setCurrentDate={(date: Moment) => {
                               setCurrentDate(date);
                               setCurrentType("DAY_VIEW");
                           }}/>
                : <></>}
                {/*: <DayView currentDate={currentDate}*/}
                {/*           appointments={appointments}*/}
                {/*           setCurrentDate={setCurrentDate}/>}*/}
        </Stack>
    </Container>;
}