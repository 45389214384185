import axios from "axios";
import moment from "moment";
import {AppointmentWindow} from "../domain";

export const getAppointmentWindowsRequest = async (serviceName: string | undefined): Promise<AppointmentWindow[]> => {
    let url = `business-hours.php${serviceName === undefined ? '' : `?service_name=${serviceName}`}`;

    const {data} = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });

    return (data || []).map((appointmentWindowResource: AppointmentWindowResource): AppointmentWindow => {
        return {
            date: moment(appointmentWindowResource.date, "YYYY-MM-DD"),
            start: moment(appointmentWindowResource.start, "HH:mm:ss"),
            end: moment(appointmentWindowResource.end, "HH:mm:ss"),
            serviceName: appointmentWindowResource.service_name,
            capacity: appointmentWindowResource.capacity
        }
    });
}

interface AppointmentWindowResource {
    date: string;
    start: string;
    end: string;
    service_name: string;
    capacity: number;
}
