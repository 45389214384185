import React from "react";
import {Avatar, Chip, Paper, Stack, TableCell} from "@mui/material";
import moment from "moment/moment";
import {Appointment, Timeslot} from "../../../domain";

type FilteredAppointment = {
    serviceName: string;
    capacity: Timeslot[];
}

function determineImage(name: string) {
    switch (name) {
        case 'FLYBOARD':
            return 'flyboard.webp';
        case 'AQUAPARK':
            return 'aquapark17.webp';
        case 'STANDUP_PADDLE':
            return 'sup.webp';
        case 'HOVERBOARD':
            return 'hoverboard.jpg';
        case 'JETOVATOR':
            return 'jetovator.jpg';
        case 'JETPACK':
            return 'jetpack.jpg';
        default:
            return '';
    }
}

function determineColor(name: string) {
    switch (name) {
        // case 'FLYBOARD':
        //     return '#F4E04D';
        // case 'AQUAPARK':
        //     return '#F2ED6F';
        // case 'STANDUP_PADDLE':
        //     return '#8CA5BA';
        // case 'HOVERBOARD':
        //     return '#8DB1AB';
        // case 'JETOVATOR':
        //     return '#587792';
        // case 'JETPACK':
        //     return '#B3D45E';
        default:
            return '#CEE397';
    }
}

interface CalendarProps {
    currentDate: moment.Moment;
    appointments: Appointment[];
    setCurrentDate: (date: moment.Moment) => void;
}


const MonthView: React.FC<CalendarProps> = (props: CalendarProps) => {
    const {currentDate, appointments} = props;

    const startOfMonth = currentDate.clone().startOf('month');
    const endOfMonth = currentDate.clone().endOf('month');
    const daysInMonth = endOfMonth.date();
    const firstDayOfWeek = startOfMonth.weekday();

    const weeks: JSX.Element[] = [];
    let currentWeek: JSX.Element[] = [];

    for (let i = 0; i < firstDayOfWeek; i++) {
        currentWeek.push(<Stack key={`empty-${i}`} sx={{
            p: 2,
            height: '12rem',
            visibility: 'hidden'
        }}>0</Stack>);
    }

    for (let day = 1; day <= (daysInMonth + 7 - endOfMonth.weekday()); day++) {
        const dayDate = startOfMonth.clone().date(day);
        const isCurrentMonth = dayDate.isSame(currentDate, 'month');

        const filteredAppointments = appointments.filter((appointment) => {
            return moment(appointment.date).isSame(dayDate);
        });

        const grouped = filteredAppointments.reduce((result: FilteredAppointment[], appointment) => {
            const existing = result.find(item => item.serviceName === appointment.serviceName);

            if (existing) {
                existing.capacity = Array.from(new Set([...existing.capacity, ...appointment.timeslots]));
            } else {
                result.push({
                    serviceName: appointment.serviceName,
                    capacity: Array.from(new Set(appointment.timeslots))
                });
            }

            return result;
        }, []);

        if (day >= daysInMonth) {
            currentWeek.push(
                <Stack key={dayDate.format('YYYY-MM-DD')}
                       className={`calendar-day ${isCurrentMonth ? '' : 'disabled'}`}
                       sx={{
                           p: 2,
                           height: '12rem',
                           visibility: 'hidden'
                       }}>
                    {day}
                </Stack>
            );
        } else {
            currentWeek.push(
                <Stack key={dayDate.format('YYYY-MM-DD')}
                       className={`calendar-day ${isCurrentMonth ? '' : 'disabled'}`}
                       // onClick={() => setCurrentDate(dayDate)}
                       sx={{
                           p: 2,
                           height: '12rem',
                           cursor: 'pointer',
                           '&:hover': {
                               backgroundColor: '#eee'
                           }
                       }}>
                    {day}
                    {grouped.map((appointment) => {
                        return {
                            serviceName: appointment.serviceName,
                            capacity: appointment.capacity.reduce((sum, timeslot) => sum + timeslot.capacity, 0)
                        };
                    }).map((appointment) => {
                        return <Chip size="small"
                                     avatar={<Avatar alt={appointment.serviceName}
                                                     src={`/static/images/${determineImage(appointment.serviceName)}`}/>}
                                     label={`${appointment.serviceName} ${appointment.capacity}`} sx={{
                            m: .4,
                            justifyContent: 'flex-start',
                            fontSize: '.6rem',
                            backgroundColor: `${determineColor(appointment.serviceName)}`
                        }}/>
                    })}
                </Stack>
            );
        }

        if (dayDate.weekday() === 6) {
            weeks.push(
                <Stack key={`week-${weeks.length}`}
                       flexDirection="row"
                       sx={{
                           '& > *': {
                               flex: '1 1 0px'
                           }
                       }}>
                    {currentWeek}
                </Stack>
            );
            currentWeek = [];
        }
    }

    return        <Stack component={Paper}>
            <Stack flexDirection="row"
                   flexWrap="nowrap"
                   sx={{
                       '& > *': {
                           flex: '1 1 0px'
                       }
                   }}>
                {[0, 1, 2, 3, 4, 5, 6].map((index: number) => {
                    return <TableCell align="center">
                        {moment().day(index).format('ddd')}
                    </TableCell>;
                })}
            </Stack>
            {weeks}
        </Stack>;
};

export default MonthView;