import axios from "axios";

export const getUserRequest = async (): Promise<string> => {
    const {data} = await axios.get('users.php', {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });

    if (data.login) {
        return data.login;
    }

    return '';
}