import BasicAppBar from "./static/BasicAppBar";
import React from "react";
import {Card, CardContent, CardMedia, Container, Grid, Paper, Stack, Typography} from "@mui/material";
import {useGetServices} from "../hooks/useGetServices";
import {useTranslation} from "react-i18next";

const Prices = (props: { changeLanguage: (language: string) => void }) => {
    const {changeLanguage} = props;
    const {services} = useGetServices(false);
    const {t} = useTranslation();

    return <>
        <BasicAppBar changeLanguage={changeLanguage}/>
        <Container component={Paper}
                   maxWidth="lg"
                   sx={{
                       my: 10,
                       p: 4
                   }}>
            <Grid container spacing={2} justifyContent="center">
                {services.map(service => (
                    <Card sx={{maxWidth: 345, m: 2}}>
                        <CardMedia sx={{height: 140}} image={`static/images/${service.image}`} title={t(service.name)}/>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {t(service.name)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                &euro; {service.price.format()} p.p.
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Grid>
        </Container>
    </>;
}

export default Prices;