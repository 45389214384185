import {useGetAppointmentWindows} from "../../hooks/useGetAppointmentWindows";
import {useTranslation} from "react-i18next";
import {
    Button,
    Container,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow
} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useGetServices} from "../../hooks/useGetServices";
import SnackBars, {SnackbarsRefs} from "../Snackbars";
import {Moment} from "moment";
import {DatePicker} from "@mui/x-date-pickers";
import ClearIcon from "@mui/icons-material/Clear";
import CreateAppointmentWindow from "../../components/dashboard/appointment-windows/CreateAppointmentWindow";
import {AppointmentWindowOverviewDetail} from "../../components/dashboard/appointment-windows/AppointmentWindowOverviewDetail";
import {useGetAvailabilities} from "../../hooks/useGetAvailabilities";
import ServiceFilter from "../../components/dashboard/ServiceFilter";
import {AppointmentWindow} from "../../domain";

interface Props {
    setTitle: (title: string) => void;
}

export default function AppointmentWindowOverview(children: Props) {
    const {setTitle} = children;
    const {t} = useTranslation();
    const {appointmentWindows, getAppointmentWindows} = useGetAppointmentWindows(undefined);
    const {availabilities, getAvailabilities} = useGetAvailabilities(undefined);
    const [selectedService, setSelectedService] = useState<string>('ALL');
    const [filteredAppointmentWindows, setFilteredAppointmentWindows] = useState<AppointmentWindow[]>(appointmentWindows);

    const {services} = useGetServices(false);
    let serviceNames = services.map(service => service.name);
    serviceNames.unshift('ALL');

    const [dialog, setDialog] = useState(false);

    const [dateFilter, setDateFilter] = useState<Moment | null>(null);

    const handleClickOpen = () => setDialog(true);

    const snackBarsRef = useRef<SnackbarsRefs>(null);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    useEffect(() => {
        setTitle(t('DASHBOARD_BUSINESS_HOURS_TITLE'));

        setFilteredAppointmentWindows(appointmentWindows
            .filter(appointmentWindow => selectedService === 'ALL' ? true : appointmentWindow.serviceName === selectedService)
            .filter(appointmentWindow => dateFilter === null || appointmentWindow.date.isSame(dateFilter, 'day'))
            .sort((a, b) => a.date.unix() - b.date.unix()));
    }, [setFilteredAppointmentWindows, setTitle, t, appointmentWindows, selectedService, dateFilter]);

    const visibleRows = useMemo(() =>
            filteredAppointmentWindows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [filteredAppointmentWindows, page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);

    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const disabled = services.length <= 0;

    return <Container>
        <SnackBars ref={snackBarsRef}
                   horizontal="left"
                   vertical="bottom"/>
        <TableContainer component={Paper} sx={{
            mt: 4
        }}>
            <Stack flexDirection="row"
                   justifyContent="space-between"
                   alignItems="center"
                   flexWrap="wrap"
                   spacing={4}
                   sx={{
                       m: 4
                   }}>
                <Stack flexDirection="row"
                       justifyContent="space-between"
                       alignItems="center">
                    <Button variant="contained"
                            onClick={handleClickOpen}
                            disabled={disabled}
                            sx={{mt: 4}}
                            id="createAppointmentWindowButton">{t('CREATE_BUSINESS_HOURS')}</Button>
                </Stack>
                <Stack flexDirection="row"
                       justifyContent="center"
                       alignItems="center">
                    <DatePicker onChange={e => {
                        setDateFilter(e);
                        setPage(0);
                    }}
                                value={dateFilter}
                                shouldDisableDate={(day: Moment) => !appointmentWindows.map(a => a.date.format("YYYY-MM-DD")).includes(day.format("YYYY-MM-DD"))}/>
                    <IconButton onClick={() => setDateFilter(null)} sx={{ml: 2}}>
                        <ClearIcon/>
                    </IconButton>
                </Stack>
                <ServiceFilter selectedService={selectedService}
                               setSelectedServiceName={(serviceName) => {
                                   setSelectedService(serviceName);
                                   setPage(0);
                               }}/>
            </Stack>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>{t('TABLE_DATE')}</TableCell>
                        <TableCell>{t('TABLE_START')}</TableCell>
                        <TableCell>{t('TABLE_END')}</TableCell>
                        <TableCell>{t('TABLE_SERVICE_NAME')}</TableCell>
                        <TableCell>{t('TABLE_CAPACITY')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {visibleRows.map(appointmentWindow => {
                        return <AppointmentWindowOverviewDetail
                            key={`${appointmentWindow.date.format("YYYY-MM-DD")} ${appointmentWindow?.serviceName}`}
                            availabilities={availabilities.filter(availability => availability.serviceName === appointmentWindow.serviceName && availability.date.isSame(appointmentWindow.date))}
                            appointmentWindow={appointmentWindow}
                            snackBarsRef={snackBarsRef}
                            refresh={() => {
                                getAppointmentWindows();
                                getAvailabilities();
                            }}/>;
                    })}
                </TableBody>
            </Table>
            <TablePagination count={filteredAppointmentWindows.length}
                             page={page}
                             rowsPerPageOptions={[5, 10, 25]}
                             rowsPerPage={rowsPerPage}
                             onPageChange={handleChangePage}
                             onRowsPerPageChange={handleChangeRowsPerPage}
                             labelDisplayedRows={
                                 ({from, to, count}) => {
                                     return '' + from + '-' + to + ' of ' + count
                                 }
                             }/>
        </TableContainer>
        <CreateAppointmentWindow snackBarsRef={snackBarsRef}
                                 dialog={dialog}
                                 openDialog={setDialog}
                                 refresh={() => {
                                 getAppointmentWindows();
                                 getAvailabilities();
                             }}/>
    </Container>;
}