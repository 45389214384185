import React from "react";
import axios from "axios";
import {SnackbarsRefs} from "../../../pages/Snackbars";
import DeleteDialog from "../../DeleteDialog";
import {Service} from "../../../domain";

interface Props {
    snackBarsRef: React.RefObject<SnackbarsRefs>;
    service: Service | undefined;
    setService: (service: Service | undefined) => void;
    refresh: () => void;
}

export default function DeleteService(children: Props) {
    const {
        snackBarsRef,
        service,
        setService,
        refresh
    } = children;

    const confirm = () => {
        setService(undefined);
        axios.delete(`services.php?name=${service!.name}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('success', 'SERVICE_DELETE_SUCCESS');
            }

            refresh();
        }).catch(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('error', 'SERVICE_DELETE_ERROR');
            }
        });
    };

    return <DeleteDialog open={service !== undefined}
                         close={() => setService(undefined)}
                         confirm={confirm}/>;
}