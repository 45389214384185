import axios from "axios";
import {EntranceCodeStatistics} from "../domain/EntranceCodeStatistics";

export const getEntranceCodeStatisticsRequest = async (): Promise<EntranceCodeStatistics> => {
    let url = 'entrance-code-statistics.php';

    const {data} = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });

    return {
        total: data.total,
        remaining: data.remaining
    };
}
