import {useParams} from "react-router-dom";
import {useGetAppointment} from "../../hooks/useGetAppointment";
import {Stack} from "@mui/material";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import AppointmentSummary from "../../components/dashboard/appointment/AppointmentSummary";

interface Props {
    setTitle: (title: string) => void;
}

export default function AppointmentDetail(children: Props) {
    const {setTitle} = children;
    const {id} = useParams();
    const {appointment} = useGetAppointment(id !== undefined ? id : '');
    const {t} = useTranslation();

    useEffect(() => {
        setTitle(t('DASHBOARD_APPOINTMENT_DETAIL_TITLE', {name: appointment?.firstName + ' ' + appointment?.lastName}));
    }, [setTitle, t, appointment]);

    return <Stack justifyContent="flex-start"
                  alignItems="center"
                  mt={4}>
        <AppointmentSummary id={id!}/>
    </Stack>;
}
