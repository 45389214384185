import {Box, Button, Collapse, Container, Divider, IconButton, Paper, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import StatusChip from "../../StatusChip";
import {grey} from "@mui/material/colors";
import axios from "axios";
import {
    ArrowBack,
    ArrowForward,
    AccessibilityNew,
    CameraAlt,
    Person,
    Delete,
    ExpandLess,
    ExpandMore,
    PictureAsPdf, Mail
} from "@mui/icons-material";
import DeleteTimeslot from "../appointments/DeleteTimeslot";
import theme from "../../../theme";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import SnackBars, {SnackbarsRefs} from "../../../pages/Snackbars";
import {useGetAppointment} from "../../../hooks/useGetAppointment";
import {Avatar} from "../../avatar";
import {Timeslot} from "../../../domain";
import {AppointmentResource} from "../../../api/AppointmentResource";

type Props = {
    id: string;
};

export default function AppointmentSummary(props: Props) {
    const {t} = useTranslation();
    const {id} = props;
    const {appointment, getAppointment} = useGetAppointment(id);
    const snackBarsRef = useRef<SnackbarsRefs>(null);

    const [collapseTimeslots, setCollapseTimeslots] = useState(false);
    const [collapseEntranceCodes, setCollapseEntranceCodes] = useState(false);
    const [collapseOptions, setCollapseOptions] = useState(false);
    const [collapseComment, setCollapseComment] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot>();

    const wetsuits = appointment?.option('WETSUITS') ?? 0;
    const goPro = appointment?.option('GOPRO') ?? 0;

    const [index, setIndex] = useState<number>(1);
    const entranceCode = appointment?.entranceCodes[index - 1];

    const setUpdateAppointments = (active: boolean) => {
        if (active) {
            getAppointment();
        }
    }

    const prev = () => {
        setIndex(index - 1);
    }

    const next = () => {
        setIndex(index + 1);
    }

    function sendConfirmationMail() {
        axios.patch(`appointments.php?id=${appointment?.id}`, AppointmentResource.of(appointment!)
        ).then(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('success', 'MAIL_SEND');
            }
        }).catch(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('error', 'ERROR_SENDING_MAIL');
            }
        });
    }

    return <Container component={Paper} maxWidth="sm" sx={{mb: 4}}>
        <SnackBars ref={snackBarsRef}
                   horizontal="left"
                   vertical="bottom"/>
        <Box sx={{
            height: '100%',
            '& h1': {
                mt: 8
            },
            '& h2': {
                mt: 2,
                mb: 2
            },
            '& > div ': {
                mt: 2,
                mb: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }
        }}>
            <Typography variant="h1" textAlign="center">{t('APPOINTMENT_SUMMARY')}</Typography>
            <Box sx={{justifyContent: 'center !important'}}>
                <Avatar name={`${appointment?.firstName} ${appointment?.lastName}`}/>
            </Box>
            <Box sx={{
                '& > p': {
                    fontSize: '1.2rem'
                }
            }}>
                <Typography>{t(appointment?.serviceName ?? '')}</Typography>
                <Typography>{appointment?.date.format("LL")}</Typography>
                <Typography>€ {appointment?.price.format()}</Typography>
            </Box>
            <Box>
                <Typography>{appointment?.checkoutUrl !== undefined && ["payment_open"].includes(appointment?.status ?? '') ?
                    <Link to={appointment?.checkoutUrl ?? ''} target="_blank"><StatusChip
                        value={appointment?.status}/></Link> :
                    <StatusChip value={appointment?.status ?? 'payment_open'}/>}</Typography>
                <IconButton aria-label="expand row"
                            size="small"
                            onClick={() => sendConfirmationMail()}>
                    <Mail/>
                </IconButton>
            </Box>
            <Box sx={{justifyContent: 'center !important'}}>
                <img width="20%"
                     alt="qr-code"
                     style={{
                         userSelect: 'none'
                     }}
                     src={`https://api.qrserver.com/v1/create-qr-code/?color=000000&bgcolor=FFFFFF&data=${appointment?.id}&qzone=1&margin=0&size=400x400&ecc=L`}/>
            </Box>
            <Typography textAlign="center" fontSize=".8rem"
                        color={grey[800]}>{appointment?.createdOn.fromNow()}</Typography>
            <Typography variant="h2">{t('APPOINTMENT_DETAILS')}</Typography>
            <Typography textAlign="right">
                <Link to={`${axios.defaults.baseURL}/appointments.php?id=${appointment?.id}&type=pdf`}>
                    <PictureAsPdf/>
                </Link>
            </Typography>
            <Box>
                <Typography color={grey[600]}>{t('NAME')}</Typography>
                <Typography>{appointment?.firstName} {appointment?.lastName}</Typography>
            </Box>
            <Box>
                <Typography color={grey[600]}>{t('EMAIL')}</Typography>
                <Typography component="a" href={`mailto:${appointment?.email}`}>{appointment?.email}</Typography>
            </Box>
            <Box>
                <Typography color={grey[600]}>{t('PHONE_NUMBER')}</Typography>
                <Typography component="a"
                            href={`tel:${appointment?.phoneNumber}`}>{appointment?.phoneNumber}</Typography>
            </Box>
        </Box>
        <Divider variant="fullWidth" sx={{m: 4}}/>
        <Box sx={{
            '& > div > div': {
                mt: 2,
                mb: 2
            }
        }}>
            <Typography variant="h2">
                <IconButton sx={{mr: 2}} onClick={() => setCollapseTimeslots(!collapseTimeslots)}>
                    {collapseTimeslots ? <ExpandLess/> : <ExpandMore/>}
                </IconButton>
                {t('APPOINTMENT_TIMESLOTS')}
            </Typography>
            <Collapse in={collapseTimeslots} timeout="auto" unmountOnExit>
                {appointment?.timeslots.map(timeslot => {
                    const deleteTimeslot = () => {
                        setOpenDeleteDialog(true);
                        setSelectedTimeslot(timeslot);
                    }

                    return <Box key={timeslot.time.format("HH:mm")}
                                component={Stack}
                                flexDirection="row"
                                justifyContent="space-between"
                                sx={{
                                    ml: 10,
                                    mr: 10
                                }}>
                        <Typography lineHeight="2.5">{timeslot.time.format("HH:mm")}</Typography>
                        <Typography sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>{timeslot.capacity} x <Person/></Typography>
                        <IconButton className="delete-timeslot" onClick={deleteTimeslot}>
                            <Delete color="error"/>
                        </IconButton>
                        <DeleteTimeslot setUpdateAppointments={setUpdateAppointments}
                                        snackBarsRef={snackBarsRef}
                                        appointmentId={appointment?.id!}
                                        timeslot={selectedTimeslot}
                                        dialog={openDeleteDialog}
                                        openDialog={setOpenDeleteDialog}/>
                    </Box>;
                })}
            </Collapse>
        </Box>
        <Divider variant="fullWidth" sx={{m: 4}}/>
        <Box>
            <Typography variant="h2" sx={{mt: 2, mb: 2}}>
                <IconButton sx={{mr: 2}} onClick={() => setCollapseEntranceCodes(!collapseEntranceCodes)}>
                    {collapseEntranceCodes ? <ExpandLess/> : <ExpandMore/>}
                </IconButton>
                {t('APPOINTMENT_ENTRANCE_CODES')}
            </Typography>
            <Collapse in={collapseEntranceCodes} timeout="auto" unmountOnExit>
                <Stack flexDirection="row"
                       justifyContent="center"
                       alignItems="center">
                    <Stack flexDirection="column"
                           justifyContent="center"
                           alignItems="center">
                        <Stack flexDirection="row"
                               justifyContent="center"
                               alignItems="center">
                            <IconButton onClick={prev}
                                        disabled={index <= 1}>
                                <ArrowBack sx={{
                                    fontSize: '2rem',
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                    border: `2px solid ${theme.palette.text.disabled}`,
                                    opacity: `${index <= 1 ? 0 : 1}`
                                }}/>
                            </IconButton>
                            <img width="50%"
                                 alt="qr-code"
                                 style={{
                                     userSelect: 'none'
                                 }}
                                 src={`https://api.qrserver.com/v1/create-qr-code/?color=000000&bgcolor=FFFFFF&data=${entranceCode?.code}&qzone=1&margin=0&size=400x400&ecc=L`}/>
                            <IconButton onClick={next}
                                        disabled={index >= (appointment?.entranceCodes.length || 0)}>
                                <ArrowForward sx={{
                                    fontSize: '2rem',
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                    border: `2px solid ${theme.palette.text.disabled}`,
                                    opacity: `${index >= (appointment?.entranceCodes.length || 0) ? 0 : 1}`
                                }}/>
                            </IconButton>
                        </Stack>
                        <Typography sx={{mb: 2}}>{entranceCode?.code}</Typography>
                        <Typography sx={{mb: 2}}>{index} / {appointment?.entranceCodes.length}</Typography>
                    </Stack>
                </Stack>
            </Collapse>
        </Box>
        {(wetsuits > 0 || goPro > 0) ? <>
            <Divider variant="fullWidth" sx={{m: 4}}/>
            <Box sx={{mb: 2}}>
                <Typography variant="h2" sx={{mt: 2, mb: 2}}>
                    <IconButton sx={{mr: 2}} onClick={() => setCollapseOptions(!collapseOptions)}>
                        {collapseOptions ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                    {t('APPOINTMENT_OPTIONS')}
                </Typography>
                <Collapse in={collapseOptions} timeout="auto" unmountOnExit>
                    <Stack alignItems="center"
                           spacing={2}>
                        {wetsuits > 0 ? <Box sx={{
                            justifyContent: 'flex-start !important'
                        }}>
                            <>{wetsuits} x <AccessibilityNew
                                sx={{verticalAlign: 'middle'}}/> {t('WETSUITS')}</>
                        </Box> : ''}
                        {(goPro > 0) ?
                            <span><CameraAlt sx={{verticalAlign: 'middle'}}/> {t('GOPRO')}</span> : ''}
                    </Stack>
                </Collapse>
            </Box>
        </> : ''}
        {appointment?.comment !== undefined && appointment.comment !== '' && appointment.comment !== null ? <>
            <Divider variant="fullWidth" sx={{m: 4}}/>
            <Box sx={{mb: 2}}>
                <Typography variant="h2" sx={{mt: 2, mb: 2}}>
                    <IconButton sx={{mr: 2}} onClick={() => setCollapseComment(!collapseComment)}>
                        {collapseComment ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                    {t('APPOINTMENT_COMMENT')}
                </Typography>
                <Collapse in={collapseComment} timeout="auto" unmountOnExit>
                    <Stack alignItems="center"
                           spacing={2}>
                        {appointment.comment}
                    </Stack>
                </Collapse>
            </Box>
        </> : <></>}
    </Container>;
};