import {
    AppBar,
    Button, ButtonPropsColorOverrides,
    ButtonPropsVariantOverrides,
    Divider,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography,
    useMediaQuery
} from "@mui/material";
import i18next from "i18next";
import {KeyboardArrowDown} from "@mui/icons-material";
import theme from "../../theme";
import MenuIcon from "@mui/icons-material/Menu";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {OverridableStringUnion} from "@mui/types";
import React, {Fragment, useState} from "react";

const logo = 'static/images/logo.webp';
const logoHome = 'static/images/logo-mini.webp';

type NavigationItem = {
    title: string;
    link: string;
    variant: OverridableStringUnion<'text' | 'outlined' | 'contained',
        ButtonPropsVariantOverrides>;
    color: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides>;
};

const navigationItems: NavigationItem[] = [
    {
        title: 'Aquapark België',
        link: '/',
        variant: 'text',
        color: 'primary'
    },
    {
        title: 'prices_title',
        link: '/prices',
        variant: 'text',
        color: 'primary'
    },
    {
        title: 'groups_title',
        link: '/groups',
        variant: 'text',
        color: 'primary'
    },
    {
        title: 'Contact',
        link: '/contact',
        variant: 'text',
        color: 'primary'
    },
    {
        title: 'FAQ',
        link: '/faq',
        variant: 'text',
        color: 'primary'
    },
    {
        title: 'tickets_title',
        link: '/appointment',
        variant: 'contained',
        color: 'secondary'
    }
];

export default function BasicAppBar(props: { changeLanguage: (language: string) => void }) {
    const {changeLanguage} = props;
    const {t} = useTranslation();

    let breakpointMatches = useMediaQuery(theme.breakpoints.down('md'));

    const [drawer, setDrawer] = useState(false);

    const toggleDrawer = () => {
        setDrawer(!drawer);
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    function toolbar() {
        if (breakpointMatches) {
            return <>
                <Button component={Link} to="/"><img src={logoHome} alt="logo" style={{
                    borderRadius: '50%',
                    height: '2rem'
                }}/></Button>
                <Button component={Link} to={navigationItems[4].link}
                        variant="contained" color="secondary">{t(navigationItems[4].title)}</Button>
                <span style={{marginLeft: '1rem'}} onClick={() => changeLanguage('nl')}>nl</span>
                <span style={{marginLeft: '1rem'}} onClick={() => changeLanguage('fr')}>fr</span>
                <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer}>
                    <MenuIcon/>
                </IconButton>
                <Drawer anchor="top" open={drawer} onClose={toggleDrawer}>
                    {navigationItems.map(navigationItem => (
                        <Fragment key={navigationItem.title}>
                            <Button component={Link} to={navigationItem.link}
                                    variant="contained"
                                    sx={{
                                        p: 2.4
                                    }}
                                    onClick={toggleDrawer}>{t(navigationItem.title)}</Button>
                            <Divider sx={{
                                backgroundColor: theme.palette.common.white,
                                height: '.2rem'
                            }}/>
                        </Fragment>
                    ))}
                </Drawer>
            </>;
        } else {
            return <>
                {navigationItems.map(navigationItem => (
                    <Button component={Link} to={navigationItem.link}
                            key={navigationItem.title}
                            variant={navigationItem.variant}
                            color={navigationItem.color}>{t(navigationItem.title)}</Button>
                ))}
                <Typography sx={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                }}>
                    <Button variant="contained" onClick={handleClick} sx={{
                        mx: '1rem'
                    }}>
                        {i18next.language} <KeyboardArrowDown/>
                    </Button>
                    <Menu anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                              elevation: 0,
                              sx: {
                                  overflow: 'visible',
                                  filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32))',
                                  mt: 1.5,
                                  '& .MuiAvatar-root': {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1
                                  },
                                  '&:before': {
                                      content: '""',
                                      display: 'block',
                                      position: 'absolute',
                                      top: 0,
                                      right: 26,
                                      width: 10,
                                      height: 10,
                                      backgroundColor: 'background.paper',
                                      transform: 'translateY(-50%) rotate(45deg)',
                                      zIndex: 0
                                  }
                              }
                          }}
                          transformOrigin={{horizontal: 'right', vertical: 'top'}}
                          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                        <MenuItem onClick={() => changeLanguage('nl')}>
                            <Typography sx={{
                                mx: '.6rem',
                                textTransform: 'uppercase',
                                fontSize: '.9rem'
                            }}>nl</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => changeLanguage('fr')}>
                            <Typography sx={{
                                mx: '.6rem',
                                textTransform: 'uppercase',
                                fontSize: '.9rem'
                            }}>fr</Typography>
                        </MenuItem>
                    </Menu>
                </Typography>
            </>;
        }
    }

    return <AppBar position="fixed">
        <Stack component={Toolbar}
               flexDirection="row"
               justifyContent="space-between">
            {toolbar()}
        </Stack>
    </AppBar>;
}