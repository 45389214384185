import {useGetServices} from "../../hooks/useGetServices";
import {
    Avatar,
    Button,
    Container,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import SnackBars, {SnackbarsRefs} from "../Snackbars";
import CreateService from "../../components/dashboard/services/CreateService";
import EditService from "../../components/dashboard/services/EditService";
import DeleteService from "../../components/dashboard/services/DeleteService";
import {Service} from "../../domain";

interface Props {
    setTitle: (title: string) => void;
}

export default function Services(children: Props) {
    const {setTitle} = children;
    const {t} = useTranslation();
    const {services, getServices} = useGetServices(false);
    const snackBarsRef = useRef<SnackbarsRefs>(null);

    useEffect(() => {
        setTitle(t('DASHBOARD_SERVICES_TITLE'));
    }, [setTitle, t]);

    const [deleteService, setDeleteService] = useState<Service>();
    const [editService, setEditService] = useState<Service>();
    const [dialog, setDialog] = useState(false);

    const handleClickOpen = () => setDialog(true);

    return <Container>
        <SnackBars ref={snackBarsRef}
                   horizontal="left"
                   vertical="bottom"/>
        <TableContainer component={Paper} sx={{
            mt: 4
        }}>
            <Stack flexDirection="row"
                   justifyContent="space-between"
                   alignItems="center"
                   sx={{
                       m: 4
                   }}>
                <Stack flexDirection="row"
                       justifyContent="space-between"
                       alignItems="center">
                    <Button variant="contained" onClick={handleClickOpen}
                            id="createServiceButton">{t('CREATE_SERVICE')}</Button>
                </Stack>
            </Stack>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('TABLE_NAME')}</TableCell>
                        <TableCell>{t('TABLE_DURATION')}</TableCell>
                        <TableCell>{t('TABLE_CAPACITY')}</TableCell>
                        <TableCell>{t('TABLE_PRICE')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {services.map(service => <TableRow key={service.name}>
                            <TableCell component="th" scope="row" sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}><Avatar alt={service.name}
                                       src={`/static/images/${service.image}`}
                                       sx={{mx: 2}}/>{t(service.name)}</TableCell>
                            <TableCell>{service.duration.format("HH:mm:ss")}</TableCell>
                            <TableCell>{service.capacity}</TableCell>
                            <TableCell>€ {service.price.format()}</TableCell>
                            <TableCell align="right">
                                <IconButton className="edit-service" onClick={() => setEditService(service)}>
                                    <EditIcon/>
                                </IconButton>
                                <IconButton className="delete-service" onClick={() => setDeleteService(service)}>
                                    <DeleteIcon color="error"/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        <EditService snackBarsRef={snackBarsRef}
                     service={editService}
                     setService={setEditService}
                     refresh={getServices}/>
        <DeleteService snackBarsRef={snackBarsRef}
                       service={deleteService}
                       setService={setDeleteService}
                       refresh={getServices}/>
        <CreateService snackBarsRef={snackBarsRef}
                       dialog={dialog}
                       openDialog={setDialog}
                       refresh={getServices}/>
    </Container>;
}
