import {Chip, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useGetServices} from "../../hooks/useGetServices";

type Props = {
    optionalServiceNames?: string[],
    disabledServiceNames?: string[],
    selectedService: string,
    setSelectedServiceName: (serviceName: string) => void;
    active?: boolean;
}

const defaultProps = {
    disabledServiceNames: [],
    active: false
};
export default function ServiceFilter(propsIn: Props) {
    const props = {...defaultProps, ...propsIn};
    const {optionalServiceNames, active, disabledServiceNames, selectedService, setSelectedServiceName} = props;
    const {t} = useTranslation();
    const {services} = useGetServices(active);

    const [serviceNames, setServiceNames] = useState<string[]>([])

    useEffect(() => {
        let names;

        if (optionalServiceNames === undefined) {
            names = services.map(service => service.name);
            names.unshift('ALL');
        } else {
            names = optionalServiceNames;
        }

        setServiceNames(names);
    }, [optionalServiceNames, services]);

    return <Stack flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center">
        {serviceNames.map(name => {
            const disabled = disabledServiceNames.includes(name) && name !== "ALL";

            return <Chip label={t(name)}
                         disabled={disabled}
                         color={selectedService === name ? "primary" : "default"}
                         onClick={() => setSelectedServiceName(name)}
                         sx={{
                             mr: 1
                         }}/>;
        })}
    </Stack>;
}