import { useTranslation } from "react-i18next";
import {Box, Grid, Typography} from "@mui/material";
import { Link } from "react-router-dom";
import theme from "../../theme";

type Category = {
    title: string;
    image: string;
    link: string;
}

const categories: Category[] = [
    {
        title: 'aquapark_title',
        image: 'static/images/aquapark02.webp',
        link: 'activities/aquapark'
    },
    {
        title: 'Stand-Up Paddle (SUP)',
        image: 'static/images/sup.webp',
        link: 'activities/sup'
    },
    {
        title: 'Flyboard',
        image: 'static/images/flyboard.webp',
        link: 'activities/flyboard'
    },
    {
        title: 'title_beachbar',
        image: 'static/images/beachbar.webp',
        link: '/'
    }
]

export default function Categories() {
    const {t} = useTranslation();

    return <Grid container component="section" spacing={2} sx={{
        mt: 4,
        mb: 4
    }}>
        {categories.map(category => (
            <Grid item xs={12} md={3} key={category.title}>
                <Box sx={{
                    position: 'relative',
                    height: '36vh',
                    [theme.breakpoints.down('md')]: {
                        height: 400
                    },
                    '& a': {
                        textDecoration: 'none'
                    },
                    '& .imageTitle': {
                        position: 'relative',
                        fontSize: '1.4rem',
                        textShadow: '1px 1px 1px #555',
                        padding: `${theme.spacing(2)} ${theme.spacing(2)} 14px`
                    },
                    '& .imageMarked': {
                        height: 3,
                        width: 18,
                        background: theme.palette.common.white,
                        position: 'absolute',
                        bottom: -2,
                        left: 'calc(50% - 9px)',
                        boxShadow: '1px 1px 1px #555',
                        transition: theme.transitions.create('opacity')
                    },
                    '&:hover': {
                        zIndex: 1
                    },
                    '&:hover .imageBackdrop': {
                        opacity: 0.1
                    },
                    '&:hover .imageMarked': {
                        opacity: 0
                    },
                    '&:hover .imageTitle': {
                        border: '4px solid currentColor'
                    }
                }}>
                    <Box sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',
                        backgroundImage: `url(${category.image})`
                    }}/>
                    <Box className="imageBackdrop" sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                        background: theme.palette.common.black,
                        opacity: 0.4,
                        transition: theme.transitions.create('opacity')
                    }}/>
                    <Box sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'common.white'
                    }}>
                        <Link to={`${category.link}`}>
                            <Typography component="h3" color='common.white' variant="h6" className="imageTitle">
                                {t(category.title)}
                                <div className="imageMarked"/>
                            </Typography>
                        </Link>
                    </Box>
                </Box>
            </Grid>
        ))}
    </Grid>;
}