import {OverridableStringUnion} from "@mui/types";
import {Appointment, EntranceCode, Option, Timeslot} from "../domain";

export class AppointmentResource {
    id: string | null;
    date: string;
    service_name: string;
    amount: number;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    language: string;
    comment: string;
    timeslots: TimeslotResource[];
    options: OptionResource[];
    confirmation: boolean;
    payment: boolean;
    created_on: string;
    price: number;
    entrance_codes: EntranceCodeResource[];
    status: OverridableStringUnion<'payment_open' |
        'payment_pending' |
        'payment_cancelled' |
        'payment_expired' |
        'payment_failed' |
        'gift_card' |
        'appointment_paid'>;
    checkout_url: string | null

    constructor(id: string | null,
                date: string,
                service_name: string,
                amount: number,
                first_name: string,
                last_name: string,
                email: string,
                phone_number: string,
                language: string,
                comment: string,
                timeslots: TimeslotResource[],
                options: OptionResource[],
                confirmation: boolean,
                payment: boolean,
                created_on: string,
                price: number,
                entrance_codes: EntranceCodeResource[],
                status: OverridableStringUnion<'payment_open' |
                    'payment_pending' |
                    'payment_cancelled' |
                    'payment_expired' |
                    'payment_failed' |
                    'gift_card' |
                    'appointment_paid'>,
                checkout_url: string | null) {
        this.id = id;
        this.date = date;
        this.service_name = service_name;
        this.amount = amount;
        this.first_name = first_name;
        this.last_name = last_name;
        this.email = email;
        this.phone_number = phone_number;
        this.language = language;
        this.comment = comment;
        this.timeslots = timeslots;
        this.options = options;
        this.confirmation = confirmation;
        this.payment = payment;
        this.created_on = created_on;
        this.price = price;
        this.entrance_codes = entrance_codes;
        this.status = status;
        this.checkout_url = checkout_url;
    }

    public static of(appointment: Appointment) {
        return new AppointmentResource(
            null,
            appointment.date.format("YYYY-MM-DD"),
            appointment.serviceName,
            appointment.amount,
            appointment.firstName,
            appointment.lastName,
            appointment.email,
            appointment.phoneNumber,
            appointment.language,
            appointment.comment,
            appointment.timeslots.map((timeslot: Timeslot) => TimeslotResource.of(timeslot)),
            appointment.options.map((option: Option) => OptionResource.of(option)),
            appointment.confirmation,
            appointment.payment,
            appointment.createdOn.format("YYYY-MM-DD HH:mm:ss"),
            appointment.price.value,
            appointment.entranceCodes.map((entranceCode: EntranceCode) => {
                return {
                    code: entranceCode.code
                }
            }),
            appointment.status,
            appointment.checkoutUrl
        );
    }
}

interface EntranceCodeResource {
    code: string;
}

class OptionResource {
    key: string;
    value: number;

    constructor(key: string, value: number) {
        this.key = key;
        this.value = value;
    }

    public static of(option: Option) {
        return new OptionResource(option.key, option.value);
    }
}

class TimeslotResource {
    time: string;
    capacity: number;

    constructor(time: string, capacity: number) {
        this.time = time;
        this.capacity = capacity;
    }

    public static of(timeslot: Timeslot) {
        return new TimeslotResource(timeslot.time.format("HH:mm"), timeslot.capacity);
    }
}