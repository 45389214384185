import {Button, DialogActions, DialogContent, FormControl, MenuItem, TextField} from "@mui/material";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import moment, {Moment} from "moment/moment";
import CustomDialog from "../../CustomDialog";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {SnackbarsRefs} from "../../../pages/Snackbars";
import {useTranslation} from "react-i18next";
import {useGetServices} from "../../../hooks/useGetServices";
import {useGetAppointmentWindows} from "../../../hooks/useGetAppointmentWindows";
import {AppointmentWindow} from "../../../domain";

interface Props {
    snackBarsRef: React.RefObject<SnackbarsRefs>;
    dialog: boolean;
    openDialog: (open: boolean) => void;
    selectedAppointmentWindow: AppointmentWindow;
    refresh: () => void;
}

export default function EditAppointmentWindow(props: Props) {
    const {
        snackBarsRef,
        dialog,
        openDialog,
        selectedAppointmentWindow,
        refresh
    } = props;
    const {t} = useTranslation();
    const {services} = useGetServices(false);
    const {appointmentWindows} = useGetAppointmentWindows(undefined);

    const [date, setDate] = useState<Moment | undefined>();
    const [start, setStart] = useState<Moment | undefined>();
    const [end, setEnd] = useState<Moment | undefined>();
    const [serviceName, setServiceName] = useState<string | undefined>();
    const [capacity, setCapacity] = useState<number | undefined>();

    let duration = serviceName !== undefined ? services.filter(service => service.name === serviceName)[0]?.duration : moment(1, 'minute');

    useEffect(() => {
        setDate(selectedAppointmentWindow?.date);
        setStart(selectedAppointmentWindow?.start);
        setEnd(selectedAppointmentWindow?.end);
        setServiceName(selectedAppointmentWindow?.serviceName);
        setCapacity(selectedAppointmentWindow?.capacity);
    }, [selectedAppointmentWindow, setDate, setStart, setEnd, setServiceName, setCapacity]);

    const confirm = () => {
        openDialog(false);

        axios.put(`business-hours.php?service_name=${serviceName}&date=${date?.format("YYYY-MM-DD")}`, {
            date: date?.format("YYYY-MM-DD"),
            service_name: serviceName,
            start: start?.format("HH:mm:ss"),
            end: end?.format("HH:mm:ss"),
            capacity: capacity
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('success', 'BUSINESS_HOURS_EDIT_SUCCESS');
            }

            refresh();
        }).catch(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('error', 'BUSINESS_HOURS_EDIT_ERROR');
            }
        });
    };

    return <CustomDialog title='TITLE_EDIT_BUSINESS_HOURS'
                         open={dialog}
                         close={() => openDialog(false)}
                         content={<>
                             <DialogContent>
                                 <FormControl fullWidth sx={{mb: 2, mt: 2}}>
                                     <TextField
                                         id="serviceName"
                                         select
                                         disabled
                                         value={serviceName}
                                         label={t('FORM_SERVICE_NAME')}
                                         onChange={e => setServiceName(e.target.value)}>
                                         {services.map(service => <MenuItem key={service.name}
                                                                            value={service.name}>{t(service.name)}</MenuItem>)}
                                     </TextField>
                                 </FormControl>
                                 <DatePicker className="date"
                                             onChange={e => setDate(e || undefined)}
                                             value={date}
                                             disabled
                                             label={t('FORM_DATE')}
                                             shouldDisableDate={(day: Moment) => serviceName === undefined || !appointmentWindows.filter(b => b.serviceName === serviceName).map(b => b.date.format("YYYY-MM-DD")).includes(day.format("YYYY-MM-DD"))}
                                             sx={{
                                                 width: '100%',
                                                 mb: 2
                                             }}/>
                                 <TimePicker className="start"
                                             onChange={e => setStart(e || undefined)}
                                             value={start}
                                             timeSteps={{
                                                 hours: 1,
                                                 minutes: duration?.hours() * 60 + duration?.minutes(),
                                                 seconds: 0
                                             }}
                                             disabled={serviceName === undefined || date === undefined}
                                             label={t('FORM_START')}
                                             sx={{
                                                 width: '100%',
                                                 mb: 2
                                             }}/>
                                 <TimePicker className="end"
                                             onChange={e => setEnd(e || undefined)}
                                             value={end}
                                             timeSteps={{
                                                 hours: 1,
                                                 minutes: duration?.hours() * 60 + duration?.minutes(),
                                                 seconds: 0
                                             }}
                                             disabled={serviceName === undefined || date === undefined || start === undefined}
                                             label={t('FORM_END')}
                                             minTime={start}
                                             sx={{
                                                 width: '100%',
                                                 mb: 2
                                             }}/>
                                 <TextField id="capacity"
                                            value={capacity}
                                            type="number"
                                            fullWidth
                                            inputProps={{inputMode: 'numeric', pattern: '[0-9]*', min: 0}}
                                            InputLabelProps={{shrink: capacity !== undefined}}
                                            onChange={e => setCapacity(Number(e.target.value))}
                                            label={t('FORM_CAPACITY')}
                                            sx={{
                                                mb: 2,
                                                "& .MuiInputBase-root": {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}/>
                             </DialogContent>
                             <DialogActions>
                                 <Button variant="contained" id="createAppointmentWindowConfirmButton"
                                         onClick={confirm}>{t('EDIT')}</Button>
                                 <Button variant="contained"
                                         onClick={() => openDialog(false)}>{t('CANCEL')}</Button>
                             </DialogActions>
                         </>}/>;
};