import {createTheme} from "@mui/material";
import {nlNL} from "@mui/material/locale";

const theme = createTheme({
        palette: {
            mode: 'light',
            background: {
                default: '#eeeeee'
            },
            primary: {
                main: '#0470C8',
                light: '#EBF6FE',
                dark: '#023864',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#023864',
                light: '#87C7FD',
                contrastText: '#ffffff',
            }
        },
        typography: {
            fontFamily: [
                '"Segoe UI"',
                'sans-serif'
            ].join(','),
            h1: {
                fontSize: '2.4rem'
            },
            h2: {
                fontSize: '1.6rem'
            }
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: '#ffffff'
                    }
                }
            }
        }
    },
    nlNL
);

export default theme;