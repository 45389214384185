import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField
} from "@mui/material";
import {TimePicker} from "@mui/x-date-pickers";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {SnackbarsRefs} from "../../../pages/Snackbars";
import {useTranslation} from "react-i18next";
import moment, {Moment} from "moment";

interface Props {
    snackBarsRef: React.RefObject<SnackbarsRefs>;
    dialog: boolean;
    openDialog: (open: boolean) => void;
    refresh: () => void;
}

export default function CreateService(props: Props) {
    const {
        snackBarsRef,
        dialog,
        openDialog,
        refresh
    } = props;
    const {t} = useTranslation();

    const [name, setName] = useState<string>();
    const [duration, setDuration] = useState<Moment>();
    const [capacity, setCapacity] = useState<number>();
    const [price, setPrice] = useState<number>();
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        setDisabled(name === undefined || duration === undefined || capacity === undefined || (capacity ?? 0) === 0 || price === undefined || (price ?? 0) === 0);
    }, [setDisabled, name, duration, capacity, price]);

    const confirm = () => {
        openDialog(false);

        axios.post('services.php', {
            name: name,
            duration: duration?.format("HH:mm:ss"),
            capacity: capacity,
            price: (price ?? 0) * 100
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('success', 'SERVICE_CREATED_SUCCESS');
            }

            refresh();
        }).catch(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('error', 'SERVICE_CREATED_ERROR');
            }
        });
    };

    return <Dialog open={dialog} onClose={() => openDialog(false)}>
        <DialogTitle>{t('TITLE_CREATE_SERVICE')}</DialogTitle>
        <DialogContent>
            <TextField id="name"
                       fullWidth
                       onChange={e => setName(e.target.value)}
                       value={name}
                       InputLabelProps={{shrink: name !== undefined}}
                       label={t('FORM_SERVICE_NAME')}
                       sx={{mb: 2, mt: 2}}/>
            <TimePicker className="duration"
                        onChange={e => setDuration(moment(e, 'HH:mm:ss') || undefined)}
                        value={duration}
                        label={t('FORM_DURATION')}
                        sx={{
                            width: '100%',
                            mb: 2
                        }}/>
            <TextField id="capacity"
                       value={capacity}
                       type="number"
                       fullWidth
                       inputProps={{inputMode: 'numeric', pattern: '[0-9]*', min: 0}}
                       InputLabelProps={{shrink: capacity !== undefined}}
                       onChange={e => setCapacity(Number(e.target.value))}
                       label={t('FORM_CAPACITY')}
                       sx={{mb: 2}}/>
            <FormControl fullWidth
                         id="price"
                         sx={{mb: 2}}>
                <InputLabel htmlFor="outlined-adornment-amount">{t('FORM_PRICE')}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<InputAdornment position="start">€</InputAdornment>}
                    label={t('FORM_PRICE')}
                    onChange={e => setPrice(Number(e.target.value))}
                    value={price}
                    type="number"
                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*', min: 0}}
                />
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" id="createServiceConfirmButton"
                    disabled={disabled}
                    onClick={confirm}>{t('CREATE')}</Button>
            <Button variant="contained" onClick={() => openDialog(false)}>{t('CANCEL')}</Button>
        </DialogActions>
    </Dialog>;
}