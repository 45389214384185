import {
    AppBar,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Toolbar,
    Typography,
    useMediaQuery
} from "@mui/material";
import React, {Fragment, ReactElement, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import {Link, Route, Routes} from "react-router-dom";
import KitesurfingIcon from '@mui/icons-material/Kitesurfing';
import ScheduleIcon from '@mui/icons-material/Schedule';
import QrCodeIcon from '@mui/icons-material/QrCode';
import GroupIcon from '@mui/icons-material/Group';
import theme from "../theme";
import {UserContext} from "../context/UserContext";
import Services from "./dashboard/Services";
import AppointmentWindowOverview from "./dashboard/AppointmentWindowOverview";
import QrCodes from "./dashboard/QrCodes";
import AppointmentDetail from "./dashboard/AppointmentDetail";
import {CalendarIcon} from "@mui/x-date-pickers";
import Calendar from "./dashboard/Calendar";
import Overview from "./dashboard/Overview";
import {Timeline} from "@mui/icons-material";
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import Login from "./Login";

const SearchAppointment = React.lazy(() => import('./dashboard/SearchAppointment'));
const Appointments = React.lazy(() => import('./dashboard/Appointments'));

interface MenuItem {
    title: string;
    items: Item[];
}

interface Item {
    title: string;
    link: string;
    icon: ReactElement;
}

const items: MenuItem[] = [
    {
        title: 'GENERAL',
        items: [
            {
                title: 'LINK_OVERVIEW',
                link: '',
                icon: <Timeline/>
            }
        ]
    },
    {
        title: 'LINK_MANAGEMENT',
        items: [
            {
                title: 'LINK_APPOINTMENTS',
                link: 'appointments',
                icon: <GroupIcon/>
            },
            {
                title: 'LINK_SEARCH_APPOINTMENT',
                link: 'appointment',
                icon: <LocalLibraryIcon/>
            },
            {
                title: 'LINK_CALENDAR',
                link: 'calendar',
                icon: <CalendarIcon/>
            },
            {
                title: 'LINK_QR_CODES',
                link: 'qr-codes',
                icon: <QrCodeIcon/>
            }
        ]
    },
    {
        title: 'LINK_CONFIGURATION',
        items: [
            {
                title: 'LINK_SERVICES',
                link: 'services',
                icon: <KitesurfingIcon/>
            },
            {
                title: 'LINK_BUSINESS_HOURS',
                link: 'business-hours',
                icon: <ScheduleIcon/>
            }
        ]
    }
];

export default function Dashboard() {
    const {t} = useTranslation();
    const [showDrawer, setShowDrawer] = useState(false);
    const {user, logout} = useContext(UserContext);
    const [title, setTitle] = useState('');

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer);
    }

    return <>
        <AppBar position="static">
            <Toolbar>
                <IconButton onClick={toggleDrawer}
                            color="inherit">
                    <MenuIcon/>
                </IconButton>
                <Stack flexDirection="row"
                       alignItems="center"
                       flexGrow="1"
                       justifyContent="space-between">
                    <Typography sx={{
                        ml: 4
                    }}>{title}</Typography>
                    <Typography>
                        {user ? <Button onClick={logout}>{t('LOGOUT')}</Button> :
                            <Button component={Link} to="/login">{t('LOGIN')}</Button>}
                    </Typography>
                </Stack>
                <Drawer anchor={useMediaQuery(theme.breakpoints.down('md')) ? "top" : "left"} open={showDrawer}
                        onClose={toggleDrawer}>
                    <List>
                        {items.map(item => <Fragment key={item.title}>
                            <Typography variant="h2" sx={{
                                fontSize: '1rem',
                                m: 2
                            }}>{item.title}</Typography>
                            {item.items.map(item => <Fragment key={item.title}>
                                <ListItem>
                                    <ListItemButton component={Link}
                                                    to={item.link}
                                                    onClick={toggleDrawer}>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText primary={t(item.title)}/>
                                    </ListItemButton>
                                </ListItem>
                            </Fragment>)}
                            <Divider/>
                        </Fragment>)}
                    </List>
                </Drawer>
            </Toolbar>
        </AppBar>
        <Routes>
            {!user && <Route path="/" element={<Login/>}/>}

            {user && (<>
                <Route path="/" element={<Overview setTitle={setTitle}/>}/>
                <Route path="/appointments" element={<Appointments setTitle={setTitle}/>}/>
                <Route path="/appointments/:id" element={<AppointmentDetail setTitle={setTitle}/>}/>
                <Route path="/appointment" element={<SearchAppointment setTitle={setTitle}/>}/>
                <Route path="/calendar" element={<Calendar setTitle={setTitle}/>}/>
                <Route path="/services" element={<Services setTitle={setTitle}/>}/>
                <Route path="/business-hours" element={<AppointmentWindowOverview setTitle={setTitle}/>}/>
                <Route path="/qr-codes" element={<QrCodes setTitle={setTitle}/>}/>
            </>)}
        </Routes>
    </>;
}