import axios from "axios";
import moment from "moment/moment";
import {TimeslotResource} from "./TimeslotResource";
import {OptionResource} from "./OptionResource";
import {EntranceCodeResource} from "./EntranceCodeResource";
import {Appointment, Timeslot, Price, Option} from "../domain";

export const getAppointmentRequest = async (id: string): Promise<Appointment> => {
    let url = 'appointments.php?id=' + id;

    const {data} = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });

    return {
        id: data.id,
        date: moment(data.date, "YYYY-MM-DD"),
        serviceName: data.service_name,
        service: undefined,
        amount: data.amount,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phoneNumber: data.phone_number,
        language: data.language,
        comment: data.comment,
        timeslots: data.timeslots ? data.timeslots.map((timeslotResource: TimeslotResource) => {
            return new Timeslot(moment(timeslotResource.time, "HH:mm:ss"), timeslotResource.capacity);
        }) : [],
        options: data.options.map((optionResource: OptionResource) => {
            return new Option(optionResource.key, optionResource.value);
        }),
        entranceCodes: data.entrance_codes.map((entranceCodeResource: EntranceCodeResource) => {
            return {
                code: entranceCodeResource.code
            }
        }),
        confirmation: data.confirmation,
        payment: data.payment,
        price: new Price(data.price),
        createdOn: moment(data.created_on),
        status: data.status,
        checkoutUrl: data.checkout_url,
        option(key: string): number {
            return data.options.filter((option: OptionResource) => option.key === key).map((option: OptionResource) => option.value)[0] || 0;
        }
    };
}
