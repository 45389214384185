import React, {Fragment, RefObject, useState} from "react";
import {SnackbarsRefs} from "../../../pages/Snackbars";
import {useTranslation} from "react-i18next";
import {Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteAppointmentWindow from "./DeleteAppointmentWindow";
import {Availability, AppointmentWindow, Timeslot} from "../../../domain";
import EditIcon from "@mui/icons-material/Edit";
import EditAppointmentWindow from "./EditAppointmentWindow";

type Props = {
    appointmentWindow: AppointmentWindow,
    availabilities: Availability[],
    snackBarsRef: RefObject<SnackbarsRefs>,
    refresh: () => void;
};

export function AppointmentWindowOverviewDetail(props: Props) {
    const {availabilities, appointmentWindow, snackBarsRef, refresh} = props;
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const deleteDialogOpen = () => setOpenDeleteDialog(true);
    const editDialogOpen = () => setOpenEditDialog(true);

    return <Fragment key={`${appointmentWindow?.date.format("YYYY-MM-DD")} ${appointmentWindow?.serviceName}`}>
        <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
            <TableCell>
                {availabilities.length !== 0 ?
                    <IconButton aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton> : <></>}
            </TableCell>
            <TableCell component="th"
                       scope="row">{appointmentWindow.date.format("YYYY-MM-DD")}</TableCell>
            <TableCell>{appointmentWindow.start.format("HH:mm:ss")}</TableCell>
            <TableCell>{appointmentWindow.end.format("HH:mm:ss")}</TableCell>
            <TableCell>{t(appointmentWindow.serviceName)}</TableCell>
            <TableCell>{appointmentWindow.capacity}</TableCell>
            <TableCell>
                <IconButton className="edit-business-hours" onClick={editDialogOpen}>
                    <EditIcon/>
                </IconButton>
                <IconButton className="delete-business-hours" onClick={deleteDialogOpen}>
                    <DeleteIcon color="error"/>
                </IconButton>
            </TableCell>
        </TableRow>
        {availabilities.length !== 0 ?
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('TABLE_TIME')}</TableCell>
                                    <TableCell>{t('TABLE_REMAINING_CAPACITY')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {availabilities
                                    .map(availability => availability.timeslots)
                                    .flat()
                                    .map((timeslot: Timeslot) =>
                                        <TableRow key={timeslot.time.format("HH:mm:ss")}
                                                  sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                            <TableCell align="right">{timeslot.time.format("HH:mm")}</TableCell>
                                            <TableCell align="right">{timeslot.capacity}</TableCell>
                                        </TableRow>
                                    )}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow> : <></>}
        <EditAppointmentWindow snackBarsRef={snackBarsRef}
                               dialog={openEditDialog}
                               openDialog={setOpenEditDialog}
                               selectedAppointmentWindow={appointmentWindow}
                               refresh={refresh}/>
        <DeleteAppointmentWindow snackBarsRef={snackBarsRef}
                                 dialog={openDeleteDialog}
                                 openDialog={setOpenDeleteDialog}
                                 date={appointmentWindow.date}
                                 serviceName={appointmentWindow.serviceName}
                                 refresh={refresh}/>
    </Fragment>;
}