import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import BasicAppBar from "./static/BasicAppBar";

export default function Activities(props: { changeLanguage: (language: string) => void }) {
    const {changeLanguage} = props;

    type Activity = {
        title: string;
        price: string;
        description: string;
        image: string;
    }

    const activities: Activity[] = [
        {title: 'title_bumperball', price: '', description: 'description_bumperball', image: ''},
        {title: 'title_archery_tag', price: '', description: 'description_archery_tag', image: ''},
        {title: 'title_XXL_gezelschapsspelen', price: '', description: 'description_XXL_gezelschapsspelen', image: ''},
        {title: 'title_actie_luchtkastelen', price: '', description: 'description_actie_luchtkastelen', image: ''},
        {title: 'title_ballon_blaster_game', price: '', description: 'description_ballon_blaster_game', image: ''},
        {title: 'title_Sumo_worstelen_XXL', price: '', description: 'description_Sumo_worstelen_XXL', image: ''},
        {title: 'title_Boogschieten', price: '', description: 'description_Boogschieten', image: ''},
        {title: 'title_Silent_Disco', price: '', description: 'description_Silent_Disco', image: ''},
        {title: 'title_Kinderspringkasteel', price: '', description: 'description_Kinderspringkasteel', image: ''},
    ];

    const {t} = useTranslation();

    return <>
        <BasicAppBar changeLanguage={changeLanguage}/>
        {activities.map(activity => (
            <div>
                <Typography variant="h5">{t(activity.title)}</Typography>
                {activity.price}
                {t(activity.description)}
                {activity.image}
            </div>
        ))}
        <p>{t('activities_note')}</p>
    </>;
}