import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField
} from "@mui/material";
import {TimePicker} from "@mui/x-date-pickers";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {SnackbarsRefs} from "../../../pages/Snackbars";
import {useTranslation} from "react-i18next";
import CustomDialog from "../../CustomDialog";
import {Moment} from "moment";
import {Price, Service} from "../../../domain";

interface Props {
    snackBarsRef: React.RefObject<SnackbarsRefs>;
    service: Service | undefined;
    setService: (service : Service | undefined) => void;
    refresh: () => void;
}

export default function EditService(children: Props) {
    const {
        snackBarsRef,
        service,
        setService,
        refresh
    } = children;
    const {t} = useTranslation();

    const [name, setName] = useState<string | undefined>();
    const [duration, setDuration] = useState<Moment | undefined>();
    const [capacity, setCapacity] = useState<number | undefined>();
    const [price, setPrice] = useState<Price | undefined>();

    useEffect(() => {
        setName(service?.name);
        setDuration(service?.duration);
        setCapacity(service?.capacity);
        setPrice(service?.price);
    }, [service, setName, setDuration, setCapacity, setPrice]);

    const confirm = () => {
        setService(undefined)
        axios.put(`services.php?name=${service?.name}`, {
            name: name,
            duration: duration?.format("HH:mm:ss"),
            capacity: capacity,
            price: price?.value
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('success', 'SERVICE_EDIT_SUCCESS');
            }

            refresh();
        }).catch(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('error', 'SERVICE_EDIT_ERROR');
            }
        });
    };

    return <CustomDialog title={t('TITLE_EDIT_SERVICE', {name: service?.name})} open={service !== undefined}
                         close={() => setService(undefined)} content={<>
        <DialogContent>
            <TimePicker className="duration"
                        onChange={e => setDuration(e == null ? undefined : e)}
                        value={duration}
                        label={t('FORM_DURATION')}
                        sx={{
                            width: '100%',
                            mb: 2
                        }}/>
            <TextField id="capacity"
                       value={capacity}
                       type="number"
                       fullWidth
                       inputProps={{inputMode: 'numeric', pattern: '[0-9]*', min: 0}}
                       InputLabelProps={{shrink: capacity !== undefined}}
                       onChange={e => setCapacity(Number(e.target.value))}
                       label={t('FORM_CAPACITY')}
                       sx={{
                           mb: 2,
                           "& .MuiInputBase-root": {
                               "& input": {
                                   textAlign: "right"
                               }
                           }
                       }}/>
            <FormControl fullWidth
                         id="price"
                         sx={{
                             mb: 2,
                             "& .MuiInputBase-root": {
                                 "& input": {
                                     textAlign: "right"
                                 }
                             }
                         }}>
                <InputLabel
                    htmlFor="outlined-adornment-amount">{t('FORM_PRICE')}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<InputAdornment position="start">€</InputAdornment>}
                    label={t('FORM_PRICE')}
                    onChange={e => setPrice(new Price(Number(e.target.value)))}
                    value={price?.format()}
                    type="number"
                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*', min: 0}}/>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" id="editServiceConfirmButton"
                    onClick={confirm} autoFocus>{t('SAVE')}</Button>
            <Button variant="contained"
                    onClick={() => setService(undefined)}>{t('CANCEL')}</Button>
        </DialogActions>
    </>}/>;
}