import {useEffect, useState} from "react";
import {getServicesRequest} from "../api/getServicesRequest";
import {Service} from "../domain";

export const useGetServices = (active: boolean) => {
    const [services, setServices] = useState<Service[]>([]);

    const getServices = async () => {
        setServices(await getServicesRequest(active));
    }

    useEffect(() => {
        getServices();
    }, []);

    return {services, getServices};
}