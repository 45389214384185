import {Chip} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {amber, green, orange, red} from "@mui/material/colors";
import {useTranslation} from "react-i18next";

export default function StatusChip(props: {
    value: OverridableStringUnion<'payment_open' |
        'payment_pending' |
        'payment_cancelled' |
        'payment_expired' |
        'payment_failed' |
        'gift_card' |
        'appointment_paid'>
}) {
    const {value} = props;
    const {t} = useTranslation();

    const determineBackgroundColor = () => {
        switch (value) {
            case "payment_open":
                return orange[100];
            case "payment_pending":
                return orange[100];
            case "payment_cancelled":
                return orange[100];
            case "payment_expired":
                return red['A700'];
            case "payment_failed":
                return red['A700'];
            case "appointment_paid":
                return green[700];
            case "gift_card":
            default:
                return '#004f95';
        }
    }

    const determineTextColor = () => {
        switch (value) {
            case "payment_open":
                return amber[700];
            case "payment_pending":
                return amber[700];
            case "payment_cancelled":
                return amber[700];
            case "payment_expired":
                return red[50];
            case "payment_failed":
                return red[50];
            case "appointment_paid":
                return green[50];
            case "gift_card":
            default:
                return '#fdffff';
        }
    }

    return <Chip label={t(value)} sx={{
        backgroundColor: `${determineBackgroundColor()}`,
        color: `${determineTextColor()}`,
        fontWeight: 600
    }}/>;
}