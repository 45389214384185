import axios from "axios";
import {AppointmentResource} from "./AppointmentResource";
import moment from "moment/moment";
import {TimeslotResource} from "./TimeslotResource";
import {OptionResource} from "./OptionResource";
import {EntranceCodeResource} from "./EntranceCodeResource";
import {Price, Appointment, Timeslot, Option} from "../domain";
import {Moment} from "moment";

export const getAppointmentsRequest = async (currentDate?: Moment): Promise<Appointment[]> => {
    let url;

    if (currentDate === undefined) {
        url = 'appointments.php?from=' + moment().subtract(1, 'month').format("YYYY-MM-DD") + '&to=' + moment().add(1, 'year').format("YYYY-MM-DD");
    } else {
        url = 'appointments.php?from=' + moment(currentDate).format("YYYY-MM-DD") + '&to=' + moment(currentDate).format("YYYY-MM-DD");
    }

    const data = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response.data)
        .catch(() => []);

    return (data || []).map((appointmentResource: AppointmentResource): Appointment => {
        return new Appointment(
            appointmentResource.id,
            moment(appointmentResource.date, "YYYY-MM-DD"),
            appointmentResource.service_name,
            appointmentResource.amount,
            appointmentResource.first_name,
            appointmentResource.last_name,
            appointmentResource.email,
            appointmentResource.phone_number,
            appointmentResource.language,
            appointmentResource.comment,
            appointmentResource.timeslots ? appointmentResource.timeslots.map((timeslotResource: TimeslotResource) => {
                return new Timeslot(moment(timeslotResource.time, "HH:mm:ss"), timeslotResource.capacity);
            }) : [],
            appointmentResource.options.map((optionResource: OptionResource) => {
                return new Option(optionResource.key, optionResource.value);
            }),
            appointmentResource.confirmation,
            appointmentResource.payment,
            moment(appointmentResource.created_on),
            new Price(appointmentResource.price),
            appointmentResource.entrance_codes.map((entranceCodeResource: EntranceCodeResource) => {
                return {
                    code: entranceCodeResource.code
                }
            }),
            appointmentResource.status,
            appointmentResource.checkout_url
        );
    });
}
