import {Stack, Box, Button, Container} from "@mui/material";
import {Fragment} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Categories from "./static/Categories";
import Information from "./static/Information";
import PracticalInformation from "./static/PracticalInformation";
import Footer from "./static/Footer";
import BasicAppBar from "./static/BasicAppBar";

const backgroundImage = 'static/images/aquapark27.webp';
const backgroundImageMobile = 'static/images/aquapark28.webp';
const logo = 'static/images/logo.webp';

function Jumbotron() {
    const {t} = useTranslation();

    return <Box sx={{
        background: `#7fc7d9 url(${backgroundImage}) no-repeat center center`,
        backgroundSize: 'cover',
        height: '40rem'
    }}>
        <Stack justifyContent="center"
               alignItems="center"
               height="inherit"
               spacing={2}>
            <img src={logo} alt="logo" style={{width: '50%', maxWidth: '40rem'}}/>
            <Button component={Link} to="/appointment" variant="contained" color="secondary">
                {t('BUTTON_RESERVE_NOW')}
            </Button>
        </Stack>
    </Box>;

    // return <Box sx={{
    //     backgroundImage: `url(${backgroundImage})`,
    //     backgroundColor: '#7fc7d9',
    //     backgroundPosition: 'center',
    //     backgroundSize: 'cover',
    //     backgroundRepeat: 'no-repeat',
    //     width: '100%',
    //     marginTop: '3.4rem'
    // }}>
    //     <Container maxWidth="sm" sx={{
    //         pt: '10rem'
    //     }} component="img" src={logo} alt="logo"/>
    //     <Button component={Link} to="/booking" variant="contained" color="secondary" sx={{
    //         display: 'flex',
    //         textAlign: 'center',
    //         flexDirection: 'column',
    //         marginLeft: 'auto',
    //         marginRight: 'auto',
    //         marginTop: '1.6rem'
    //     }}>{t('button_reserve_now')}</Button>
    // </Box>;
}

// const Categories = lazy(() => import('./components/Categories'))
// const Information = lazy(() => import('./components/Information'))
// const PracticalInformation = lazy(() => import('./components/PracticalInformation'))
// const Footer = lazy(() => import('./components/Footer'))

export default function Home(props: { changeLanguage: (language: string) => void }) {
    const {changeLanguage} = props;

    return <Fragment>
        <BasicAppBar changeLanguage={changeLanguage}/>
        <Jumbotron/>
        <Container maxWidth="xl">
            <Categories/>
        </Container>
        <Information/>
        <PracticalInformation/>
        <Footer/>
    </Fragment>;
}