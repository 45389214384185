import {Button, DialogActions, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import CustomDialog from "./CustomDialog";

interface Props {
    open: boolean;
    close: () => void;
    confirm: () => void;
}

export default function DeleteDialog(props: Props) {
    const {open, close, confirm} = props;
    const {t} = useTranslation();

    return <CustomDialog open={open}
                         close={close}
                         title='DELETE_DIALOG_TITLE'
                         content={<Stack component={DialogActions}
                                         flexDirection="row">
                             <Button variant="contained" className="confirm"
                                     onClick={confirm} autoFocus>{t('CONFIRM_DELETE')}</Button>
                             <Button variant="contained" className="cancel"
                                     onClick={close}>{t('CANCEL_DIALOG')}</Button>
                         </Stack>}/>;
};