import React from "react";
import axios from "axios";
import {SnackbarsRefs} from "../../../pages/Snackbars";
import DeleteDialog from "../../DeleteDialog";
import {Timeslot} from "../../../domain";

interface Props {
    snackBarsRef: React.RefObject<SnackbarsRefs>;
    dialog: boolean;
    openDialog: (open: boolean) => void;
    appointmentId: string;
    timeslot: Timeslot | undefined;
    setUpdateAppointments: (active: boolean) => void;
}

export default function DeleteTimeslot(children: Props) {
    const {
        snackBarsRef,
        dialog,
        openDialog,
        appointmentId,
        timeslot,
        setUpdateAppointments
    } = children;

    const confirm = () => {
        openDialog(false);
        axios.delete(`timeslots.php?appointment_id=${appointmentId}&time=${timeslot?.time.format("HH:mm:ss")}&capacity=${timeslot?.capacity}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('success', 'TIMESLOT_DELETE_SUCCESS');
            }

            setUpdateAppointments(true);
        }).catch(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('error', 'TIMESLOT_DELETE_ERROR');
            }
        });
    };

    return <DeleteDialog open={dialog}
                         close={() => openDialog(false)}
                         confirm={confirm}/>;
}