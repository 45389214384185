import {Moment} from 'moment';
import {Price} from "./price";

export class Service {
    name: string;
    image: string;
    capacity: number;
    duration: Moment;
    price: Price;

    getImageName(name: string): string {
        const imageMap: Record<string, string> = {
            'FLYBOARD': 'flyboard.webp',
            'AQUAPARK': 'aquapark17.webp',
            'STANDUP_PADDLE': 'sup.webp',
            'HOVERBOARD': 'hoverboard.jpg',
            'JETOVATOR': 'jetovator.jpg',
            'JETPACK': 'jetpack.jpg',
        };

        return imageMap[name] || '';
    }

    constructor(
        name: string,
        capacity: number,
        duration: Moment,
        price: Price
    ) {
        this.name = name;
        this.capacity = capacity;
        this.duration = duration;

        this.image = this.getImageName(name);

        this.price = price;
    }
}