import {Avatar as MuiAvatar} from '@mui/material';
import React from 'react';

export function Avatar(props: { name: string }) {
    const {name} = props;

    const stringToColor = (string: string) => {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }

        return color;
    };

    const stringAvatar = (fullName: string) => {
        let initials = fullName.split(" ").map(c => c[0]);
        let fontSize = 1.4 - (0.2 * initials.length);

        return {
            sx: {
                backgroundColor: stringToColor(fullName),
                fontSize: `${fontSize}rem`
            },
            children: initials.join(""),
        };
    };

    return <MuiAvatar {...stringAvatar(name)}/>;
}
