import {Moment} from 'moment';
import {Timeslot} from "./timeslot";
import {Option} from "./option";
import {EntranceCode} from "./entrance-code";
import {Price} from './price';
import {OverridableStringUnion} from "@mui/types";
import {Service} from "./service";

export class Appointment {
    id: string | null;
    date: Moment;
    amount: number;
    serviceName: string;
    service: Service | undefined;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    language: string;
    comment: string;
    timeslots: Timeslot[];
    options: Option[];
    confirmation: boolean;
    payment: boolean;
    createdOn: Moment;
    price: Price;
    entranceCodes: EntranceCode[];
    status: OverridableStringUnion<'payment_open' |
        'payment_pending' |
        'payment_cancelled' |
        'payment_expired' |
        'payment_failed' |
        'gift_card' |
        'appointment_paid'>;
    checkoutUrl: string | null;

    constructor(id: string | null,
                date: Moment,
                serviceName: string,
                amount: number,
                firstName: string,
                lastName: string,
                email: string,
                phoneNumber: string,
                language: string,
                comment: string,
                timeslots: Timeslot[],
                options: Option[],
                confirmation: boolean,
                payment: boolean,
                createdOn: Moment,
                price: Price,
                entranceCodes: EntranceCode[],
                status: OverridableStringUnion<'payment_open' |
                    'payment_pending' |
                    'payment_cancelled' |
                    'payment_expired' |
                    'payment_failed' |
                    'gift_card' |
                    'appointment_paid'>,
                checkoutUrl: string | null,
                service?: Service | undefined) {
        this.id = id;
        this.date = date;
        this.serviceName = serviceName;
        this.amount = amount;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.language = language;
        this.comment = comment;
        this.timeslots = timeslots;
        this.options = options;
        this.confirmation = confirmation;
        this.payment = payment;
        this.createdOn = createdOn;
        this.price = price;
        this.entranceCodes = entranceCodes;
        this.status = status;
        this.checkoutUrl = checkoutUrl;
        this.service = service;
    }

    option(key: string): number {
        return this.options.filter(option => option.key === key).map(option => option.value)[0] || 0;
    }
}