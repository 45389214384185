import React from "react";
import axios from "axios";
import {SnackbarsRefs} from "../../../pages/Snackbars";
import DeleteDialog from "../../DeleteDialog";
import {Moment} from "moment/moment";

interface Props {
    snackBarsRef: React.RefObject<SnackbarsRefs>;
    dialog: boolean;
    openDialog: (open: boolean) => void;
    date: Moment;
    serviceName: string;
    refresh: () => void;
}

export default function DeleteAppointmentWindow(children: Props) {
    const {
        snackBarsRef,
        dialog,
        openDialog,
        date,
        serviceName,
        refresh
    } = children;

    const confirm = () => {
        openDialog(false);
        axios.delete(`business-hours.php?date=${date.format("YYYY-MM-DD")}&service_name=${serviceName}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('success','BUSINESS_HOURS_DELETE_SUCCESS');
            }

            refresh();
        }).catch(() => {
            if (snackBarsRef.current) {
                snackBarsRef.current.openSnackbar('error','BUSINESS_HOURS_DELETE_ERROR');
            }
        });
    };

    return <DeleteDialog open={dialog}
                         close={() => openDialog(false)}
                         confirm={confirm}/>;
}