import {Box, Button, Divider, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Facebook, Instagram} from "@mui/icons-material";
import {grey} from "@mui/material/colors";
import AcceptedPaymentMethods from "./AcceptedPaymentMethods";

export default function Footer() {
    const {t} = useTranslation();

    return <Box sx={{
        backgroundColor: '#fff'
    }}>
        <Divider/>
        <Box component="footer" sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: '1rem',
            p: '1rem'
        }}>
            <Button component={Link} to='/appointment' variant="contained" color="secondary">
                {t('button_reserve_now')}
            </Button>
        </Box>
        <ul>
            <li><Link to="/">{t('homepage_title')}</Link></li>
            <li><Link to="/groups">{t('groups_title')}</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
            <li><Link to="/appointment">Tickets</Link></li>
        </ul>
        <Typography sx={{
            m: '2rem',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '-5rem',
        }}>
            <Button variant="contained" component="a" href="https://www.facebook.com/aquaparkbelgie.be/"
                    target="_blank" sx={{
                mx: '1rem'
            }}>
                <Facebook/>
            </Button>
            <Button variant="contained" component="a" href="https://www.instagram.com/aquapark_belgie/"
                    target="_blank">
                <Instagram/>
            </Button>
        </Typography>
        <AcceptedPaymentMethods/>
        <Typography sx={{
            color: grey[600],
            m: '2rem',
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            pb: '2rem'
        }}>
            {t('btw')}: BE 0524643801<br/>
            BNP Paribas Fortis:<br/>
            BE60 0017 2079 6770 (BIC GEBABEBB)<br/>
            {'Aquapark België © '} {new Date().getFullYear()}<br/>
            <Typography component="a" href={`tel:0032477398118`} sx={{color: 'inherit', textDecoration: 'none'}}>+32
                477 39 81 18</Typography>
        </Typography>
    </Box>;
}