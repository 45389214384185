import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import dutch from './nl/translation.json';
import french from './fr/translation.json';

import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/nl';
import {i18nextPlugin} from "translation-check";

i18n.use(initReactI18next).use(i18nextPlugin).init({
    lng: 'nl',
    fallbackLng: 'nl',
    resources: {
        nl: {
            translation: dutch
        },
        fr: {
            translation: french
        }
    } as const
});

i18n.on('languageChanged', function (lng) {
    moment.locale(lng);
});

export default i18n;