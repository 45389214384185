import {useEffect, useState} from "react";
import {getAppointmentWindowsRequest} from "../api/getAppointmentWindowsRequest";
import {AppointmentWindow} from "../domain";

export const useGetAppointmentWindows = (serviceName: string | undefined) => {
    const [appointmentWindows, setAppointmentWindows] = useState<AppointmentWindow[]>([]);

    const getAppointmentWindows = async () => {
        setAppointmentWindows(await getAppointmentWindowsRequest(serviceName));
    }

    useEffect(() => {
        getAppointmentWindows();

    }, []);

    return {appointmentWindows, getAppointmentWindows};
}