import {useGetEntranceCodeStatistics} from "../../hooks/useGetEntranceCodeStatistics";
import {Container, Paper, Stack, styled, Typography} from "@mui/material";
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    }
}));

interface Props {
    setTitle: (title: string) => void;
}

export default function QrCodes(children: Props) {
    const {setTitle} = children;
    const {t} = useTranslation();
    const {entranceCodeStatistics} = useGetEntranceCodeStatistics();

    useEffect(() => {
        setTitle(t('DASHBOARD_QR_CODES_TITLE'));
    }, [setTitle, t]);

    return (<Container sx={{
        pb: 10
    }}>
        <Stack component={Paper}
               sx={{
                   mt: 4
               }}>
            <Container sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 4
            }}>
                <Typography sx={{
                    pr: 4
                }}>{entranceCodeStatistics === undefined ? 0 : Math.round(entranceCodeStatistics.remaining / entranceCodeStatistics.total * 100)}% {t('REMAINING')}</Typography>
                <BorderLinearProgress variant="determinate"
                                      value={entranceCodeStatistics === undefined ? 0 : entranceCodeStatistics.remaining / entranceCodeStatistics.total * 100}
                                      sx={{
                                          flexGrow: 1,
                                          m: '2rem 0 2rem 0'
                                      }}/>
            </Container>
        </Stack>
    </Container>);
}