import {useEffect, useState} from "react";
import {getAppointmentsRequest} from "../api/getAppointmentsRequest";
import {Appointment} from "../domain";
import {Moment} from "moment";

export const useGetAppointments = (currentDate?: Moment) => {
    const [appointments, setAppointments] = useState<Appointment[]>([]);

    const getAppointments = async () => {
        setAppointments(await getAppointmentsRequest(currentDate));
    }

    useEffect(() => {
        getAppointments();
    }, []);

    return {appointments, getAppointments};
}