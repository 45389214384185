import {Alert, Snackbar} from "@mui/material";
import React, {forwardRef, Ref, useImperativeHandle, useState} from "react";
import {OverridableStringUnion} from "@mui/types";
import {useTranslation} from "react-i18next";

interface Props {
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'center' | 'right';
}

export interface SnackbarsRefs {
    openSnackbar: (type: OverridableStringUnion<'success' | 'error'>, title: string) => void
}

const SnackBars = forwardRef((children: Props, ref: Ref<SnackbarsRefs>) => {
    const {vertical, horizontal} = children;
    const {t} = useTranslation();

    const [type, setType] = useState<OverridableStringUnion<'success' | 'error'>>('success');
    const [title, setTitle] = useState('');
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        openSnackbar
    }));

    function openSnackbar(type: OverridableStringUnion<'success' | 'error'>, title: string) {
        setType(type);
        setTitle(title);
        setOpen(true);
    }

    function closeSnackbar(event?: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }

    return <Snackbar open={open}
                     anchorOrigin={{
                         vertical: vertical === undefined ? 'top' : vertical,
                         horizontal: horizontal === undefined ? 'center' : horizontal
                     }}
                     autoHideDuration={6000}
                     onClose={closeSnackbar}>
        <Alert severity={type} variant="filled" onClose={closeSnackbar}>{t(title)}</Alert>
    </Snackbar>;
});

export default SnackBars;