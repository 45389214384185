import moment from "moment";
import {Price, Service} from "../domain";

export const getServicesRequest = async (active: boolean): Promise<Service[]> => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/private/api/services.php${active ? '?active=true' : ''}`, {
        method: 'GET'
    });

    return (await response.json() as ServiceResource[]).map((serviceResource: ServiceResource) => new ServiceResource(
        serviceResource.name,
        serviceResource.capacity,
        serviceResource.duration,
        serviceResource.price
    ).toService());
}

class ServiceResource {
    name: string;
    capacity: number;
    duration: string;
    price: number;

    constructor(name: string, capacity: number, duration: string, price: number) {
        this.name = name;
        this.capacity = capacity;
        this.duration = duration;
        this.price = price;
    }

    toService(): Service {
        return new Service(
            this.name,
            this.capacity,
            moment(this.duration, 'HH:mm:ss'),
            new Price(this.price)
        );
    }
}
