import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Chip,
    Collapse,
    Container,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useGetAppointments} from "../../hooks/useGetAppointments";
import {Appointment, Option, Timeslot} from "../../domain";
import moment, {Moment} from "moment";
import {useNavigate} from "react-router-dom";
import {ArrowLeftIcon, ArrowRightIcon, DatePicker} from "@mui/x-date-pickers";
import StatusChip from "../../components/StatusChip";
import {useGetServices} from "../../hooks/useGetServices";
import ServiceFilter from "../../components/dashboard/ServiceFilter";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteTimeslot from "../../components/dashboard/appointments/DeleteTimeslot";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {SnackbarsRefs} from "../Snackbars";
import {CameraAlt} from "@mui/icons-material";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";

type RowProps = {
    timeslot: Timeslot;
    filteredAppointments: Appointment[];
    index: number;
    setUpdateAppointments: () => void;
}

function Row(rowProps: RowProps) {
    const {timeslot, index, filteredAppointments, setUpdateAppointments} = rowProps;
    const {t} = useTranslation();
    let appointment = filteredAppointments.find(appointment => appointment.timeslots.includes(timeslot));
    const navigate = useNavigate();
    const snackBarsRef = useRef<SnackbarsRefs>(null);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot>();

    const [open, setOpen] = useState(false);

    return <>
        <TableRow key={index}>
            <TableCell sx={{width: '6rem'}}>
                <IconButton aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </TableCell>
            <TableCell component="th" scope="row" onClick={() => {
                navigate(`/dashboard/appointments/${appointment?.id}`);
            }} sx={{
                cursor: 'pointer',
                width: '14rem'
            }}><Chip
                label={`${appointment?.firstName} ${appointment?.lastName}`}/></TableCell>
            <TableCell
                sx={{width: '10rem'}}>{t(appointment!.serviceName)}</TableCell>
            <TableCell sx={{width: '4rem'}}>{timeslot.capacity}</TableCell>
            <TableCell
                sx={{width: '6rem'}}>€ {appointment?.price.format()}</TableCell>
            <TableCell sx={{width: '10rem'}}>
                <Stack flexDirection="row" alignItems="center">
                    {appointment?.options.map((option: Option) => {
                        if (option.key === 'GOPRO') {
                            return <CameraAlt/>;
                        }

                        if (option.key === 'WETSUITS' && option.value > 0) {
                            return <>{option.value} x <AccessibilityNewIcon/></>;
                        }
                    })}
                </Stack>
            </TableCell>
            <TableCell align="center"><StatusChip
                value={appointment?.status ?? 'payment_open'}/></TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{margin: 1}}>
                        <Typography variant="h6" gutterBottom component="div">
                            {t('TITLE_TIMESLOTS')}
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('TABLE_TIME')}</TableCell>
                                    <TableCell>{t('TABLE_CAPACITY')}</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {appointment?.timeslots.map((timeslot: Timeslot) => {
                                    const deleteTimeslot = () => {
                                        setOpenDeleteDialog(true);
                                        setSelectedTimeslot(timeslot);
                                    }

                                    return <TableRow
                                        key={timeslot.time.format("HH:mm")}>
                                        <TableCell align="center" component="th"
                                                   scope="row">
                                            {timeslot.time.format("HH:mm:ss")}
                                        </TableCell>
                                        <TableCell
                                            align="center">{timeslot.capacity}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                className="delete-timeslot"
                                                onClick={deleteTimeslot}>
                                                <DeleteIcon color="error"/>
                                            </IconButton>
                                            <DeleteTimeslot
                                                setUpdateAppointments={setUpdateAppointments}
                                                snackBarsRef={snackBarsRef}
                                                appointmentId={appointment!.id!}
                                                timeslot={selectedTimeslot}
                                                dialog={openDeleteDialog}
                                                openDialog={setOpenDeleteDialog}/>
                                        </TableCell>
                                    </TableRow>;
                                })}
                            </TableBody>
                        </Table>

                        {(appointment?.options.length ?? 0 > 0 ? <>
                            <Typography variant="h6" gutterBottom
                                        component="div" sx={{
                                mt: 2
                            }}>
                                {t('TITLE_OPTIONS')}
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('TABLE_KEY')}</TableCell>
                                        <TableCell>{t('TABLE_VALUE')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {appointment?.options.map((option: Option) => {
                                        if (option.key === 'GOPRO') {
                                            return <TableRow key={option.key}>
                                                <TableCell align="right"
                                                           component="th"
                                                           scope="row">{t(option.key)}
                                                </TableCell>
                                                <TableCell
                                                    align="right">{t((option.value === 1 ? 'YES' : 'NO'))}</TableCell>
                                            </TableRow>;
                                        } else {
                                            return <TableRow key={option.key}>
                                                <TableCell align="right"
                                                           component="th"
                                                           scope="row">{t(option.key)}
                                                </TableCell>
                                                <TableCell
                                                    align="right">{option.value}</TableCell>
                                            </TableRow>;
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </> : '')}
                        <Box sx={{
                            m: '2rem 0 2rem 0'
                        }}>{appointment?.comment}</Box>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>;
}

type Props = {
    setTitle: (title: string) => void
}

export default function Overview(props: Props) {
    const {setTitle} = props;
    const {t} = useTranslation();

    const {services} = useGetServices(true);
    const [currentDate, setCurrentDate] = useState<Moment>(moment());
    const [selectedService, setSelectedService] = useState<string>('ALL');

    const {appointments, getAppointments} = useGetAppointments();
    const [filteredAppointments, setFilteredAppointments] = useState(appointments
        .filter(appointment => appointment.date.isSame(currentDate, 'day')));

    let serviceNames = services.map(service => service.name);
    serviceNames.unshift('ALL');

    filteredAppointments.forEach(item => item.timeslots.sort((a, b) => a.time.isSameOrBefore(b.time) ? -1 : 1));

    const allTimeslots: Timeslot[] = filteredAppointments.flatMap(item => item.timeslots);
    allTimeslots
        .sort((a, b) => a.time.isSameOrBefore(b.time) ? -1 : 1);

    const groupedTimeslots: { [key: string]: Timeslot[] } = {};

    allTimeslots.forEach(timeslot => {
        const key = `${timeslot.time.format("HH:mm:ss")}`;
        if (!groupedTimeslots[key]) {
            groupedTimeslots[key] = [];
        }
        groupedTimeslots[key].push(timeslot);
    });

    useEffect(() => {
        setTitle(t('DASHBOARD_OVERVIEW_TITLE'));

        setFilteredAppointments(appointments
            .filter(appointment => appointment.date.isSame(currentDate, 'day'))
            .filter(appointment => selectedService === 'ALL' ? true : appointment.serviceName === selectedService));
    }, [t, setTitle, setFilteredAppointments, appointments, currentDate, selectedService, getAppointments]);

    const serviceTimeSlots: Record<string, Set<string>> = {};

    filteredAppointments.forEach(item => {
        const {serviceName, timeslots} = item;

        if (!serviceTimeSlots[serviceName]) {
            serviceTimeSlots[serviceName] = new Set();
        }

        timeslots.forEach(slot => {
            serviceTimeSlots[serviceName].add(slot.time.format("HH:mm:ss"));
        });
    });

    const formattedData = Object.entries(serviceTimeSlots).map(([key, timeslots]) => ({
        key,
        amount: timeslots.size
    }));

    // const fetchAppointments = useCallback((currentDate?: Moment) => {
    //     const {appointments, getAppointments} = useGetAppointments(currentDate);
    //     setFilteredAppointments(appointments.filter(appointment => appointment.date.isSame(currentDate, 'day')));
    // }, []);

    // const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    async function updateAppointments(currentDate: moment.Moment) {
        setCurrentDate(currentDate);

        // fetchAppointments(currentDate);
    }

    return <Container sx={{
        pb: 10
    }}>
        <Stack component={Paper}
               sx={{
                   mt: 4
               }}>

            <Stack flexDirection="row"
                   alignItems="center"
                   flexWrap="wrap"
                   spacing={2}
                   justifyContent="space-between"
                   sx={{
                       m: 2,
                       '& > *': {
                           ml: 2,
                           mr: 2
                       }
                   }}>
                <div>
                    <Button variant="contained" onClick={() => updateAppointments(moment())}>
                        {t('TODAY')}
                    </Button>
                    <IconButton aria-label="expand row"
                                size="small"
                                onClick={() => {
                                    updateAppointments(currentDate.clone().subtract(1, 'day'));
                                    setSelectedService("ALL");
                                }}
                                sx={{
                                    ml: 2,
                                    mr: 2
                                }}>
                        <ArrowLeftIcon/>
                    </IconButton>
                    <IconButton aria-label="expand row"
                                size="small"
                                onClick={() => {
                                    updateAppointments(currentDate.clone().add(1, 'day'));
                                    setSelectedService("ALL");
                                }}
                                sx={{
                                    ml: 2,
                                    mr: 2
                                }}>
                        <ArrowRightIcon/>
                    </IconButton>
                    <span>{currentDate.format("MMMM D, YYYY")}</span>
                </div>
                {/*<ToggleButtonGroup exclusive defaultValue="ALL" onChange={(e, value) => setUpcoming(value !== "ALL")}>*/}
                {/*    <ToggleButton value="ALL">{t('ALL')}</ToggleButton>*/}
                {/*    <ToggleButton value="UPCOMING">{t('UPCOMING')}</ToggleButton>*/}
                {/*</ToggleButtonGroup>*/}
                <Stack flexDirection="row"
                       justifyContent="center"
                       alignItems="center">
                    <DatePicker onChange={e => setCurrentDate(e || moment())}
                                value={currentDate}
                                shouldDisableDate={(day: Moment) => !appointments.map(a => a.date.format("YYYY-MM-DD")).includes(day.format("YYYY-MM-DD"))}/>
                </Stack>
                {/*<PieChart width={1200} height={220}>*/}
                {/*    <Pie*/}
                {/*        data={formattedData}*/}
                {/*        cx={300}*/}
                {/*        cy={110}*/}
                {/*        innerRadius={60}*/}
                {/*        outerRadius={80}*/}
                {/*        fill="#8884d8"*/}
                {/*        paddingAngle={5}*/}
                {/*        dataKey="amount"*/}
                {/*        label={(entry) => entry.key + " (" + entry.amount + ")"}*/}
                {/*    >*/}
                {/*        {formattedData.map((entry, index) => (*/}
                {/*            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>*/}
                {/*        ))}*/}
                {/*    </Pie>*/}
                {/*    <Pie*/}
                {/*        data={formattedData}*/}
                {/*        cx={800}*/}
                {/*        cy={110}*/}
                {/*        startAngle={180}*/}
                {/*        endAngle={0}*/}
                {/*        innerRadius={60}*/}
                {/*        outerRadius={80}*/}
                {/*        fill="#8884d8"*/}
                {/*        paddingAngle={5}*/}
                {/*        dataKey="amount"*/}
                {/*        label={(entry) => entry.key + " (" + entry.amount + ")"}*/}
                {/*    >*/}
                {/*        {formattedData.map((entry, index) => (*/}
                {/*            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>*/}
                {/*        ))}*/}
                {/*    </Pie>*/}
                {/*</PieChart>*/}
                <ServiceFilter optionalServiceNames={serviceNames}
                               disabledServiceNames={serviceNames.filter(serviceName => !(appointments
                                   .filter(appointment => appointment.date.isSame(currentDate, 'day'))
                                   .map(a => a.serviceName)).includes(serviceName))}
                               selectedService={selectedService}
                               setSelectedServiceName={setSelectedService}/>
            </Stack>
            <Table>
                <TableBody>
                    {Object.keys(groupedTimeslots).length === 0 ? <TableRow>
                        <Stack justifyContent="center"
                               alignItems="center"
                               sx={{
                                   minHeight: '20rem'
                               }}>
                            {t('NO_APPOINTMENTS_ON_THIS_DATE')}
                        </Stack>
                    </TableRow> : <>{Object.keys(groupedTimeslots).map(key => {
                        // const timeslots = upcoming ? groupedTimeslot.filter(t => t.time.isAfter(moment())) : groupedTimeslot;
                        const timeslots = groupedTimeslots[key];
                        const verticalAlign = timeslots.length === 1 ? 'middle' : 'top';

                        return <TableRow key={key}>
                            <TableCell
                                sx={{verticalAlign: verticalAlign}}>{timeslots[0].time.format("HH:mm:ss")}</TableCell>
                            <Table>
                                <TableBody>
                                    {timeslots.map((timeslot, index) => {
                                        return <Row timeslot={timeslot}
                                                    filteredAppointments={filteredAppointments}
                                                    index={index}
                                                    setUpdateAppointments={getAppointments}/>
                                    })}
                                </TableBody>
                            </Table>
                        </TableRow>;
                    })}</>}
                </TableBody>
            </Table>
        </Stack>
    </Container>;
}
