import {Typography} from "@mui/material";

export default function AcceptedPaymentMethods() {
    return <Typography sx={{
        m: '2rem',
        mt: '4rem',
        display: 'flex',
        justifyContent: 'flex-end'
    }}>
        <span
            style={{
                backgroundImage: "url('static/images/payment-methods/bancontact.51194c38.svg')",
                backgroundPosition: 'center center',
                height: 28,
                width: 40
            }}/>
        <span
            style={{
                backgroundImage: "url('static/images/payment-methods/visa.4d814ca8.svg')",
                backgroundPosition: 'center center',
                height: 28,
                width: 40
            }}/>
        <span
            style={{
                backgroundImage: "url('static/images/payment-methods/mastercard.4515bea9.svg')",
                backgroundPosition: 'center center',
                height: 28,
                width: 40
            }}/>
        <span
            style={{
                backgroundImage: "url('static/images/payment-methods/american-express.b0bdffae.svg')",
                backgroundPosition: 'center center',
                height: 28,
                width: 40
            }}/>
        <span
            style={{
                backgroundImage: "url('static/images/payment-methods/maestro.dfcd74bb.svg')",
                backgroundPosition: 'center center',
                height: 28,
                width: 40
            }}/>
        <span
            style={{
                backgroundImage: "url('static/images/payment-methods/jcb.08735536.svg')",
                backgroundPosition: 'center center',
                height: 28,
                width: 40
            }}/>
    </Typography>;
}