import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function ScrollToTop(props: any) {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>
}