import {Box, Container, Grid, Paper, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {AccessTime, DirectionsBus, LocationOn} from "@mui/icons-material";
import OpeningHours from "./OpeningHours";

const logo = 'static/images/klein-strand.webp';

export default function PracticalInformation() {
    const {t} = useTranslation();

    return <Container sx={{my: 6}}>
        <Grid container component={Paper} justifyContent="center" flexDirection="row" xs={12} sx={{mb: 6}}>
            <Grid item xs={12} md={4} sx={{p: 4}}>
                <Typography variant="h5"
                            sx={{mb: 2, fontWeight: 'bolder'}}><LocationOn/> {t('title_location')}
                </Typography>
                <Typography variant="h6">Klein Strand <img src={logo} style={{
                    position: "absolute",
                    height: '4rem',
                    marginLeft: '3.4rem',
                    marginTop: '-3rem'
                }} alt="logo"/></Typography>
                <Typography>Varsenareweg 29, 8490 Jabbeke (BE)</Typography>
                <Typography variant="h5" sx={{
                    mt: 4,
                    mb: 2,
                    fontWeight: 'bolder'
                }}><DirectionsBus/> {t('title_directions')}</Typography>
                <ul>
                    <li>{t('description_directions_1')}</li>
                    <li>{t('description_directions_2')}</li>
                </ul>
            </Grid>
            <Grid item xs={12} md={8}>
                <iframe title="google-maps" width="100%" height="380" id="gmap_canvas"
                        src="https://maps.google.com/maps?q=klein%20strand,%20jabbeke&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        frameBorder="0" scrolling="no"/>
            </Grid>
        </Grid>
        {/*contact (info@aquaparkbelgie.be, info@aquaparkbelgique.be, info@aquaparkbelgium.be)*/
        }
        <Box component={Paper} sx={{p: 2}}>
            <Typography variant="h5" sx={{
                mb: 2,
                fontWeight: 'bolder'
            }}><AccessTime/> {t('title_opening_hours')}</Typography>
            <OpeningHours/>
        </Box>
    </Container>;
};