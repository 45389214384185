import {Dialog, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ReactElement} from "react";

interface Props {
    title: string;
    open: boolean;
    close: () => void;
    content: ReactElement;
}

export default function CustomDialog(props: Props) {
    const {title, open, close, content} = props;
    const {t} = useTranslation();

    return <Dialog open={open} onClose={close} disableRestoreFocus>
        <DialogTitle sx={{textAlign: 'center'}}>{t(title)}</DialogTitle>
        {content}
    </Dialog>;
};