import {Box, Grid, Typography} from "@mui/material";
import theme from "../../theme";
import {useTranslation} from "react-i18next";
import {Cloud, ContactSupport, LocationOn, Person, Support} from "@mui/icons-material";

const backgroundImage = 'static/images/curvy-lines.png';

export default function Information() {
    const {t} = useTranslation();

    return <Box sx={{
        backgroundColor: theme.palette.primary.light,
        backgroundImage: `url(${backgroundImage})`,
        p: 6
    }}>
        <Grid container spacing={5}>
            <Grid item xs={12} md={12 / 5}>
                <Typography variant="h5" align="center"><ContactSupport/><br/>{t('title_who')}</Typography>
                <Typography align="center">{t('information_description_who')}</Typography>
            </Grid>
            <Grid item xs={12} md={12 / 5}>
                <Typography variant="h5" align="center"><Person/><br/>{t('title_what')}</Typography>
                <Typography align="center">{t('information_description_what')}</Typography>
            </Grid>
            <Grid item xs={12} md={12 / 5}>
                <Typography variant="h5" align="center"><LocationOn/><br/>{t('title_where')}</Typography>
                <Typography align="center">{t('information_description_where')}</Typography>
            </Grid>
            <Grid item xs={12} md={12 / 5}>
                <Typography variant="h5" align="center"><Cloud/><br/>{t('title_weather')}</Typography>
                <Typography align="center">{t('information_description_weather')}</Typography>
            </Grid>
            <Grid item xs={12} md={12 / 5}>
                <Typography variant="h5" align="center"><Support/><br/>{t('title_security')}</Typography>
                <Typography align="center">{t('information_description_safety')}</Typography>
            </Grid>
        </Grid>
    </Box>;
}