import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import {grey} from "@mui/material/colors";

export default function OpeningHours() {
    const {t} = useTranslation();

    function createDay(
        name: string,
        hours1: string,
        hours2: string,
        selected: string) {
        return {name, hours1, hours2, selected};
    }

    const rows = [
        createDay('Maandag', 'on_request', '13u - 18u', isSelected(1)),
        createDay('Dinsdag', 'on_request', '13u - 18u', isSelected(2)),
        createDay('Woensdag', '13u - 18u', '13u - 18u', isSelected(3)),
        createDay('Donderdag', 'on_request', '13u - 18u', isSelected(4)),
        createDay('Vrijdag', 'on_request', '13u - 18u', isSelected(5)),
        createDay('Zaterdag', '13u - 18u', '13u - 18u', isSelected(6)),
        createDay('Zondag', '13u - 18u', '13u - 18u', isSelected(0)),
    ]

    function isSelected(day: number) {
        const date = new Date();

        if ((date.getMonth() + 1) === 5 || (date.getMonth() + 1) === 6 || (date.getMonth() + 1) === 9) {
            if (date.getDay() === day) {
                return "hours1";
            }
        }

        if ((date.getMonth() + 1) === 7 || (date.getMonth() + 1) === 8) {
            if (date.getDay() === day) {
                return "hours2";
            }
        }

        return "";
    }

    return <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
            <TableContainer component={Paper}>
                <Table sx={{backgroundColor: grey[100]}}>
                    <TableHead>
                        <TableRow sx={{backgroundColor: theme.palette.warning.main}}>
                            <TableCell align="center" colSpan={2}
                                       sx={{
                                           textTransform: 'uppercase',
                                           fontWeight: 'bolder'
                                       }}>{t('out_of_season_months')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{textTransform: 'uppercase'}}>
                        {rows.map((row) => {
                            return row.selected === "hours1" ? (
                                <TableRow key={row.name}
                                          sx={{backgroundColor: theme.palette.primary.main}}>
                                    <TableCell>{t(row.name)}</TableCell>
                                    <TableCell sx={{textAlign: 'right'}}>{t(row.hours1)}</TableCell>
                                </TableRow>
                            ) : (
                                <TableRow key={row.name}>
                                    <TableCell sx={{
                                        fontWeight: 'bolder'
                                    }}>{t(row.name)}</TableCell>
                                    <TableCell sx={{
                                        textAlign: 'right',
                                        fontWeight: 'bolder'
                                    }}>{t(row.hours1)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
            <TableContainer component={Paper}>
                <Table sx={{backgroundColor: grey[100]}}>
                    <TableHead>
                        <TableRow sx={{backgroundColor: theme.palette.warning.main}}>
                            <TableCell align="center" colSpan={2}
                                       sx={{
                                           textTransform: 'uppercase',
                                           fontWeight: 'bolder'
                                       }}>{t('seasonal_months')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{textTransform: 'uppercase'}}>
                        {rows.map((row) => {
                            return row.selected === "hours2" ? (
                                <TableRow key={row.name}
                                          sx={{backgroundColor: theme.palette.primary.main}}>
                                    <TableCell>{t(row.name)}</TableCell>
                                    <TableCell sx={{textAlign: 'right'}}>{t(row.hours2)}</TableCell>
                                </TableRow>
                            ) : (
                                <TableRow key={row.name}>
                                    <TableCell sx={{
                                        fontWeight: 'bolder'
                                    }}>{t(row.name)}</TableCell>
                                    <TableCell sx={{
                                        textAlign: 'right',
                                        fontWeight: 'bolder'
                                    }}>{t(row.hours2)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>;
}