import {useEffect, useState} from "react";
import {getEntranceCodeStatisticsRequest} from "../api/getEntranceCodeStatisticsRequest";
import {EntranceCodeStatistics} from "../domain/EntranceCodeStatistics";

export const useGetEntranceCodeStatistics = () => {
    const [entranceCodeStatistics, setEntranceCodeStatistics] = useState<EntranceCodeStatistics>();

    useEffect(() => {
        const getEntranceCodeStatistics = async () => {
            setEntranceCodeStatistics(await getEntranceCodeStatisticsRequest());
        }

        getEntranceCodeStatistics();
    }, []);

    return {entranceCodeStatistics};
}